.row {
  display: flex;
  gap: 100px;
}

.label {
  background-color: var(--ocean-20);
  width: fit-content;
  padding: 10px;
  margin-bottom: 36px;
}

.bradAboutContainer {
  width: 70%;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header {
  padding-bottom: 48px;
}

.statusContainer {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.bottomSeparator {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--off-light-grey);
}

.content {
  padding-bottom: 20px;
}

.list {
  padding: 0 0 40px 20px;
  list-style-type: none;
  margin: 0;
}

.list li {
  color: var(--slate-85);
  position: relative;
}

.list li:before {
  display: block;
  content: '';
  background: var(--slate-85);
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 11px;
  left: -12px;
}

.modal {
  width: 343px;
  background: var(--lemon-60);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: default;
}

.modalHeader {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.modalHeader svg {
  margin: 0;
  width: 24px;
  height: 24px;
}

.modalHeader svg:last-of-type {
  margin-left: auto;
}

.modal hr {
  width: 100%;
  opacity: 0.25;
  border: 1px solid var(--slate-darken-20);
}

.emergencyCallout {
  color: var(--slate-darken-20) !important;
}

.modal button {
  cursor: default;
  width: 100%;
  font-size: 16px;
}

.modal.expanded {
  padding: 24px;
}

.modal.expanded svg:first-of-type {
  width: 32px;
  height: 32px;
}
