.card {
  display: flex;
  min-width: 350px;
  padding: 12px 14px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.errorCard {
  display: inline-flex;
  align-items: flex-start;
  font-weight: 400;
  color: var(--slate);
  background-color: var(--razz-20);
}

.alertIcon {
  margin-right: 11px;
  width: 18px;
  height: 18px;
}

.errorMessage {
  padding-top: 1px;
}
