.common {
  border-radius: 4px;
  font-size: 14px;
  line-height: 23px;
  padding: 12px;
  height: 38px;
  border: 1px solid var(--off-light-grey);
  color: var(--secondary);
  background: var(--white);
  outline: none;
}

.common:focus {
  border-color: var(--apple);
}

.common::placeholder {
  color: var(--grey-4);
}

.input {
  composes: common;
}

.textarea {
  composes: common;
  padding: 6px 12px;
  resize: none;
}

.error {
  border-color: var(--red-4);
}

.option {
  display: flex;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--slate-15);
  color: var(--slate-darken-20);
  padding: 8px 0 9px 12px;
  /* hack around a chrome rendering bug when zoomed */
  margin: 0.16px;
  background: var(--white);
  cursor: pointer;
  font-size: 12px;
}

.option:hover {
  background: var(--slate-05);
}

.optionEllipse {
  margin-right: 8px;
}

.optionWrapper:first-child .option {
  border-radius: 4px 4px 0 0;
}

.optionWrapper:last-child .option {
  border-radius: 0 0 4px 4px;
  border-width: 1px 1px 1px 1px;
}

.optionWrapper.selected .option {
  background: var(--apple-20);
  border-width: 1px 1px 1px 1px;
  border-color: var(--eden);
}

.optionWrapper.selected + .optionWrapper .option {
  border-width: 0 1px 0 1px;
}

.optionWrapper.selected + .optionWrapper:last-child .option {
  border-width: 0 1px 1px 1px;
}
