.modal {
  padding: 36px !important;
}
.closeContainer {
  cursor: pointer;
  color: var(--grey-4);
  position: absolute;
  right: 40px;
  top: 40px;
}
.inputContainer {
  margin-bottom: 24px;
  position: relative;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 36px;
}

.headerText {
  margin-left: 12px;
}

.personalizationButton {
  position: absolute;
  top: -2px;
  font-size: 10px;
  line-height: 14px;
  right: 0;
  padding: 4px 6px;
}

.label {
}

.title,
.text {
  width: 100%;
}

.text {
  height: 200px;
}

.submitContainer {
  display: flex;
  justify-content: space-between;
}

.delete {
  color: var(--off-slate);
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.previewButton {
  margin-right: 12px;
}

.submit {
}
