.container {
  margin: 0 18px;
}

.section {
  margin-bottom: 24px;
}

.section:first-child {
  margin-top: 48px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.header svg {
  vertical-align: -3px;
}

.title {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
}
