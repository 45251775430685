.text {
  line-height: 1.5 !important;
  word-break: break-word;
}

.textPending {
  composes: text;
  color: var(--grey-30) !important;
}

.textFailed {
  composes: text;
  color: var(--grey-30) !important;
}

.textDeleted {
  composes: text;
  color: var(--slate-55) !important;
}

text > ul,
.text > ol {
  all: unset;
  display: flex;
  flex-direction: column;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 15px;
  white-space: normal;
}

.text > ol {
  list-style-type: decimal;
}

.text pre {
  white-space: normal;
}

.underText {
  color: var(--grey-30) !important;
  position: relative;
  font-size: 10px;
  margin-top: 6px;
  line-height: 1.4;
}

.deletedMessageContent .underText {
  display: none;
}

.notSent {
  display: flex;
  align-items: center;
}

.notSentText {
  color: var(--watermelon-red);
}
.retryText {
  cursor: pointer;
  margin-left: 5px;
  font-weight: 600;
}
.retryText {
  display: inline-flex;
  align-items: center;
}
.redoIcon {
  height: 10px;
  width: 10px;
  stroke: var(--grey-30);
  margin-right: 4px;
}

.loader {
  height: 1em !important;
  width: 1em !important;
  top: 1px !important;
  left: auto !important;
  margin-right: 5px !important;
  position: relative !important;
  display: inline-block !important;
  transform: unset !important;
}
.loader:after,
.loader:before {
  left: 0 !important;
  margin: 0 !important;
  height: 1em !important;
  width: 1em !important;
}
