.basicDetails {
  cursor: pointer;
  min-height: 46px;
}

.pastDetails {
  display: flex;
  justify-content: space-between;
}

.basicDetails button svg {
  margin: 0 1rem 0 0;
}

.toggle {
  height: 24px;
}

.headerContainer {
  display: inline-block;
}

.header {
  margin-bottom: 6px;
  font-weight: 600;
}

.detailHeader {
  line-height: 150%;
  margin-right: 5px;
}

.subDetails {
  display: flex;
}

.note {
  padding-bottom: 6px;
}

.separator {
  padding: 0 6px;
}

.ap {
  border-top: 1px solid var(--slate-15);
  margin-top: 10px;
  padding-top: 12px;
}

.apHeader {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--slate-darken-20);
}

.apBody {
  padding-top: 12px;
  padding-bottom: 6px;
  padding-right: 16px;
  padding-left: 22px;
}

.apSubheader {
  color: var(--slate-darken-20);
}

.apHeader svg {
  margin-right: 8px;
}

.apList {
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  list-style: none;
}

.apLi {
  padding-left: 8px;
  padding-top: 6px;
  display: flex;
  line-height: 100%;
}

.apLi:before {
  content: '•';
  padding-right: 6px;
}

.apLi,
.subDetails,
.note {
  color: var(--slate-darken-20);
}

.avsHeader {
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--slate-darken-20);
}

.avsCornerActions {
  float: right;
  margin-left: auto;
}

.avsHelpWrapper {
  height: 14px;
  width: 14px;
  cursor: help;
  display: inline-block;
}

.avsRetractWrapper {
  display: inline-block;
  right: 0;
  padding: 16px;
  background-color: var(--white);
  position: absolute;
  border-radius: 6px;
  font-weight: normal;
  font-size: 12px;
  width: 236px;
  z-index: 12;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.avsHelpHover {
  display: none;
}

.avsHelpWrapper:hover .avsHelpHover {
  display: inline-block;
  position: relative;
  right: 212px;
  top: 8px;
  color: var(--white);
  background-color: var(--slate-darken-20);
  border-radius: 6px;
  font-weight: normal;
  font-size: 12px;
  width: 242px;
  padding: 10px 12px;
  z-index: 12;
  float: left;
}

.retractHeader {
  color: var(--slate-darken-20);
  font-size: 20px;
  font-weight: 600px;
  margin-bottom: 18px;
}

.retractBody {
  background-color: var(--lemon-20);
  padding: 8px;
  color: var(--slate-85);
}

.avsHelpWrapper:hover .avsHelpHover.ready {
  white-space: pre;
}

.dropdownStyle {
  right: 0;
  width: 104px;
}

.hide {
  display: none;
}

.retractButton {
  width: 160px;
  float: right;
  margin-top: 16px;
  color: var(--white);
  border: 0;
  padding: 6px 18px;
  border-radius: 4px;
  font-weight: 600;
  background-color: var(--razz);
  cursor: pointer;
}

.retractButton:hover {
  background-color: var(--razz-darken-20);
}

.retractButtonLoading {
  width: 160px;
  float: right;
  margin-top: 16px;
  color: var(--white);
  border: 0;
  padding: 6px 18px;
  border-radius: 4px;
  font-weight: 600;
  background-color: var(--razz-darken-20);
  cursor: not-allowed;
}

.avsHelpHover::before {
  content: ' ';
  position: absolute;
  transform: translateY(-108%);
  right: 13px;
  border: medium solid var(--slate-darken-20);
  border-width: 10px;
  border-color: transparent transparent var(--slate-darken-20) transparent;
  z-index: 1;
  width: 0;
}

.avsHelpIcon {
  stroke: var(--slate-85);
  vertical-align: middle;
  width: 14px;
}

.avsDropdown {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  padding-top: 2px;
  padding-left: 3px;
}

.avsDropdown:hover {
  background-color: var(--slate-15);
}

.avsDropdown.dropdownOpen {
  background-color: var(--slate-darken-20);
}

.dropdownOpen .avsEllipsisIcon {
  stroke: var(--white);
}

.avsEllipsisIcon {
  stroke: var(--slate-85);
  width: 14px;
  margin-top: -4px;
}

.avsBody {
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
  background-color: var(--slate-05);
  color: var(--slate);
}

.avsBody.avsBodyEdit {
  border: 1px solid var(--slate-15);
  background-color: var(--white);
}

.topButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.bottomButtonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.editAVSButton {
  width: 89px;
  padding: 6px 16px;
  background-color: var(--slate-darken-20);
  color: var(--white);
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.saveAVSButton {
  width: 89px;
  padding: 6px 16px;
  margin-left: 4px;
  background-color: var(--slate-darken-20);
  color: var(--white);
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.cancelEditButton {
  width: 89px;
  padding: 6px 16px;
  margin-right: 4px;
  background-color: var(--white);
  color: var(--slate-darken-20);
  border-radius: 4px;
  border: 1px solid var(--slate-darken-20);
  cursor: pointer;
}

.deleteAVSButton {
  width: 115px;
  padding: 6px 16px;
  background-color: var(--white);
  color: var(--razz);
  border-radius: 4px;
  border: 1px solid var(--razz);
  cursor: pointer;
}

.cancelEditButton:hover,
.deleteAVSButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.confirmAVSButton {
  width: 154px;
  padding: 6px 16px;
  background-color: var(--slate-darken-20);
  color: var(--white);
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}

.avsContent[contenteditable='true'] {
  border: none;
  outline: none;
}

.avsContent:empty:before {
  content: attr(custom-placeholder);
  color: var(--slate-55);
  cursor: text;
}

.avsBodyEdit .footnote {
  font-size: 10px;
  line-height: 14px;
  color: var(--off-slate);
  padding-top: 6px;
}

.sendingAVS {
  cursor: not-allowed;
}

.loadingSpinner {
  padding-left: 15px;
}

.leftWrapper {
  text-align: right;
  display: inline-block;
  float: right;
}

.ellipse {
  cursor: not-allowed;
  margin: 0;
  padding: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 18px;
  width: 18px;
}

.dropdown {
  cursor: pointer;
  fill: var(--slate-55);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 18px;
  width: 18px;
  border-radius: 4px;
}

.dropdown:hover {
  fill: var(--slate-85);
}

.avsStatus {
  color: var(--slate-darken-20);
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 1px;
}

.avsStatus span {
  vertical-align: middle;
}

.avsReady {
  background-color: var(--lemon-40);
  border-radius: 4px;
  padding: 0 6px;
}

.clipboardIcon {
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
  stroke: var(--slate-darken-20);
}

.avsSent .clipboardIcon {
  stroke: var(--slate-55);
}

.avsReady .clipboardIcon {
  stroke: var(--slate-darken-20);
}

.avsHeader .clipboardIcon {
  stroke: var(--slate-55);
}

.noAP {
  font-family: 'Open Sans', sans-serif;
  line-height: 15px;
  font-size: 10px;
  text-align: right;
  color: var(--secondary-900);
}

.appointmentDetail {
  display: flex;
}

.statusInBodyPill {
  background-color: var(--slate-15);
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
}

.statusInBodyPill.sent {
  background-color: var(--apple-40);
}

.statusInBodyPill.ready {
  background-color: var(--lemon-40);
}

.statusInBodyPill.editing {
  background-color: var(--grape-20);
}

.pastControls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.refreshWrapper {
  width: 16px;
  height: 16px;
  margin-left: auto;
}

.refreshWrapper.loading .refreshIcon {
  -webkit-animation: spin 1s ease-in-out infinite;
  -moz-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
}

.refreshWrapper:hover {
  background-color: var(--slate-05);
}

.refreshWrapper:hover .refreshIcon {
  stroke: var(--slate-85);
}

.refreshIcon {
  stroke: var(--slate-55);
}

.screenersAssigned {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.screenersAssignedLabel {
  color: var(--slate);
  font-weight: 700;
  margin-left: 6px;
  display: inline;
}

.screenersModal {
  max-width: 450px;
}

.screenersModalButton {
  width: 169px;
  height: 40px;
  float: right;
  margin-top: 32px;
}

.screenersModalExplainer {
  line-height: 22.88px;
}

.screenersModalHeaderContainer {
  display: flex;
  align-items: center;
}

.screenersModalHeaderContainer span {
  padding-left: 12px;
}

.screenersModalListItem {
  display: flex;
  align-items: center;
}

.screenersModalList .screenersModalListItem:not(:last-child) {
  margin-bottom: 8px;
}

.screenersModalListItemText {
  font-size: 13px;
  line-height: 21.24px;
  padding-left: 8px;
}

.screenersModalListHeader {
  font-weight: 600;
  color: var(--slate-darken-20);
  margin-bottom: 8px;
}

.screenersModalSection {
  margin-bottom: 16px;
}

.checkInLabel {
  color: var(--eden-darken-10);
  margin-top: 1px;
}

.visitHeader {
  display: flex;
  justify-content: space-between;
}

.visitContents {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkedInCard {
  min-height: 46px;
}

.checkedInHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.checkedInHeader {
  font-weight: 600;
  padding-right: 6px;
  margin-bottom: 6px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.actions {
  margin-top: 0.75rem;
  width: 100%;
}
