.container {
  width: 450px;
  display: flex;
  align-content: center;
}

.buttonText {
  padding: 6px;
}

.statusText {
  font-size: 14px;
  line-height: 35px;
  color: var(--slate);
  padding-left: 16px;
}

.error {
  color: var(--razz-darken-20);
}

.success {
  color: var(--eden-darken-10);
}
