.modal {
  padding: 36px !important;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subheader {
  text-align: center;
}

.title {
  margin-top: 30px;
  margin-bottom: 12px;
}

.textContainer {
  margin: 6px 0;
  padding: 18px 24px;
  background: var(--off-light-grey);
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
}

.keepEditingButton {
  margin-right: 12px;
}
