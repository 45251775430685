.tooltipContainer {
    position: absolute;
    right: 0;
    top: 0;
}

.dropdown {
    width: 316px;
    max-height: 345px;
    overflow-y: auto;
}

.dropdown > .multiFilterItem:last-child {
    font-style: italic;
}

.dropdown .multiFilterItem {
    font-size: 12px;
}

.multiFilterItem {
    font-size: 11px;
    color: var(--slate-darken-20);
    padding: 8px;
}

.multiFilterItem > span {
    display: flex;
    align-items: center;
    gap: 8px;
}

.multiFilterButton {
    border: 1px solid var(--slate-15);
    border-radius: 6px;
    padding: 10px 12px;
}

.multiFilterButton > div {
    color: var(--slate-darken-20);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 292px;
    font-size: 14px;
    line-height: 20px;
}

.multiFilterButton.placeholder > div {
    color: var(--slate-45);
}

.multiFilterButton > div > div {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clearFiltersContainer {
    width: 100%;
    text-align: right;
}

.clearFilters {
    background-color: transparent;
    color: var(--action-primary);
    font-size: 12px;
    padding: 12px 18px 0;
}