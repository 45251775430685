.container {
  text-align: left;
  background-color: var(--white);
  border: 1px solid var(--off-light-grey);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
  margin-bottom: 8px;
}

.loadingContainer {
  text-align: left;
  display: flex;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.suggestionHeader {
  font-weight: bold;
  background-color: var(--accent-e1);
  border-radius: 4px;
  padding: 4px;
  display: inline-block;
  margin-bottom: 4px;
}

.suggestionHeaderError {
  background-color: var(--razz-10);
}

.regenerateButton,
.minMaxButton {
  cursor: pointer;
  background: none;
  border: 0;
}

.minMaxButton {
  vertical-align: 4px;
}

.suggestionBody {
  padding-left: 4px;
  padding-right: 4px;
  max-height: 180px;
  overflow-y: auto;
  white-space: pre-wrap;
}

.insertButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.insertButton {
  border: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--eden-darken-10);
  background: none;
  margin-top: 4px;
  margin-bottom: 8px;
  cursor: pointer;
}
