.container:last-child {
  padding-bottom: 24px;
}

.modifyButton {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  color: var(--slate-darken-20);
  border-radius: 4px;
  width: 50px;
  height: 20px;
  padding: 2px 8px 2px 8px;
}

.modifyButton:hover {
  cursor: pointer;
  background-color: var(--slate-05);
}

.container {
  margin: 0 18px;
}
