.buttonWrapper {
  margin-right: 18px;
}

.button {
  width: 40px;
  height: 40px;
  background-color: var(--slate-05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.buttonOpen {
  background-color: var(--slate-15);
}

.button:hover {
  background-color: var(--slate-15);
}

.menuItem {
  display: flex;
}

.menuItem svg {
  margin-right: 10px;
}
