.header {
  margin-bottom: 12px;
  color: var(--slate);
}

.sendIcon {
  margin-right: 12px;
  vertical-align: -4px;
}

.main {
  flex: 1;
  width: 522px;
  overflow-y: auto;
}

.instructions {
  max-width: 450px;
  margin-bottom: 32px;
  font-weight: 400;
  color: var(--slate-85);
}

.fieldWithIcon {
  position: relative;
}

.fieldWithIcon input {
  padding-left: 43px;
}

.fieldWithIcon svg {
  position: absolute;
  top: 8px;
  left: 14px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}

.button {
  width: auto;
  height: 44px;
  padding: 12px 24px;
}

.cancelButton,
.cancelButton:active {
  margin-right: 8px;
  color: var(--slate-darken-20);
  background: var(--slate-05);
}

.filename {
  width: 100%;
  margin-bottom: 10px;
}

.labelWithLink {
  display: flex;
  justify-content: space-between;
}

.label {
  display: block;
  margin-bottom: 8px;
  text-align: left;
  font-weight: var(--semibold);
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-darken-20);
}

.buttonLink {
  margin-bottom: 8px;
  border: 0;
  font-size: 12px;
  color: var(--eden-darken-10);
  background: none;
}

.required::after {
  content: ' *';
  color: var(--razz-darken-20);
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.selector {
  margin-bottom: 26px;
}
