.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 18px;
  background: var(--nearer-white);
  border-radius: 6px;
  color: var(--slate-darken-20);
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--grey-10);
}

.headerName {
  display: flex;
  flex: 0 0 108px;
  font-weight: bold;
  justify-content: flex-start;
}

.headerModified {
  display: flex;
  flex: 1;
  font-style: italic;
  justify-content: left;
  position: relative;
}

.headerModified .tooltip {
  visibility: hidden;
  display: inline-block;
  background-color: var(--grey-80);
  color: var(--white);
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 110%;
  opacity: 0;
  font-style: normal;
  transition: opacity 0.3s;
}

.headerModified:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.headerToggle {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  cursor: pointer;
  z-index: 1;
  font-weight: bold;
}

.headerToggle:focus {
  outline: none;
}

.activeItem {
  font-style: normal;
  padding: 2px;
}

.inactiveItem {
  font-style: italic;
  padding: 2px;
}

.hiddenItem {
  display: none;
}

.nkda {
  font-weight: bold;
}

.itemList {
  flex: 1;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.listcontainer {
  height: 100%;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
}
