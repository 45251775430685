.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  width: 522px;
  background-color: var(--white);
  border-radius: 6px;
  outline: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 32px;
  margin-bottom: 8px;
}

.spacer {
  flex-grow: 1;
}

.icon {
  margin-right: 17px;
}

.instructions {
  margin-right: 48px;
  margin-bottom: 32px;
}

.name {
  color: var(--slate-darken-20);
  font-weight: 600;
}

.bottomNav {
  display: flex;
  flex-display: row;
  justify-content: flex-end;
  width: 100%;
}

.button {
  width: 151px;
  height: 40px;
  background: var(--razz);
}

.cancel,
.cancel:active,
.cancel:focus {
  margin-right: 8px;
  background: var(--slate-05);
  color: var(--slate-darken-20);
}
