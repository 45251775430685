.infoBlockContainer {
  display: flex;
  padding: 4px;
  justify-content: space-between;
  width: 91%;
}

.warning {
  margin-bottom: 24px;
  padding: 12px;
  background-color: var(--lemon-10);
  border-radius: 8px;
}

.footnote {
  margin: 24px 0 24px 8px;
}

.accountInfo {
  padding: 8px;
}

.accountInfoFocused {
  background-color: var(--slate-05);
  display: flex;
}

.patientName {
  font-weight: 600;
  margin-bottom: 4px;
}

.email {
  margin-bottom: 4px;
}

.editButton {
  display: flex;
  cursor: pointer;
  height: 20px;
}

.editText {
  margin-right: 8px;
  line-height: 8px;
  color: var(--slate-darken-20) !important;
}

.infoBlock {
  width: 200px;
  overflow: hidden;
  word-wrap: break-word;
  margin-right: 25px;
}

.mrnInfo {
  font-weight: 600;
  margin: 0 0 4px 12px;
}

@media only screen and (max-width: 900px) {
  .accountInfo {
    width: 90%;
  }

  .infoBlockContainer {
    display: block;
  }

  .infoBlock {
    margin-bottom: 25px;
    width: 100%;
  }

  .infoBlock:last-child {
    margin-bottom: 0px;
  }
}

.errorText {
  font-size: 14px;
  color: var(--razz-darken-20) !important;
}
