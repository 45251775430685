.card {
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  padding: 12px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;
  position: relative;
}

.info {
  flex-grow: 1;
}

.icon {
  margin-right: 8px;
}

.stepDetails {
  color: var(--slate-85);
  margin-bottom: 4px;
}

.displayName {
  text-transform: capitalize;
  font-weight: 600;
  line-height: 20px;
}

.providerNote {
  font-size: 14px;
  font-weight: 400;
  color: var(--slate-85);
  margin-bottom: 12px;
}

.dueDateDetail {
  font-size: 14px;
  line-height: 20px;
}

.dueDateDetail.dueToday,
.dueDateDetail.overdue {
  color: var(--razz);
}

.ellipsis {
  margin-left: 8px;
}

.tagBar {
  margin-bottom: 8px;
}
