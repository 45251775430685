.visitLocation {
  display: flex;
  padding-bottom: 12px;
}

.icon {
  margin-right: 16px;
}

.visitLocation .name {
  font-weight: 600;
  margin-bottom: 4px;
  color: var(--slate-darken-20);
}

.visitLocation .line {
  color: var(--slate);
}
