.header {
  margin: 18px 40px;
}

.top {
  margin: 18px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.search {
  margin: 0 20px;
  min-width: 400px;
}
