.statusTag {
  display: flex;
  align-items: center;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 4px 8px;
  white-space: nowrap;
}

.statusTag.declined,
.statusTag.overdue {
  color: var(--razz);
  background-color: var(--razz-10);
}

.statusTag.completed {
  color: var(--eden);
  background-color: var(--apple-10);
}

.statusTag.dueToday {
  color: var(--slate-darken-20);
  background-color: var(--lemon-20);
}

.statusTag.deleted {
  color: var(--slate);
  background-color: var(--slate-05);
}
