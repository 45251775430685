.smallDropdown {
  width: 144px;
}

.largeDropdown {
  width: 281px;
}

.multiFilterItem {
  font-size: 11px;
  color: var(--slate-darken-20);
  padding: 8px;
}

.count {
  margin-left: auto;
}

.multiFilterItem > span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.multiFilterButton {
  border: 1px solid var(--slate-15);
  border-radius: 6px;
}

.multiFilterButton > div {
  font-size: 11px;
  line-height: 15px;
  color: var(--slate-darken-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multiFilterButton.placeholder > div {
  color: var(--slate-45);
}

.multiFilterButton > div > div {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiFilterButton.small > div {
  width: 128px;
}

.multiFilterButton.large > div {
  width: 265px;
}
