.macroPopup {
  padding: 0 !important;
  display: block;
  overflow-y: scroll;
  overflow-x: scroll;
  height: 400px;
  position: relative !important;
  top: 0 !important;
  min-width: 550px !important;
}

.closeHeader {
  display: none !important;
}

@media (max-width: 768px) {
  .macroPopup {
    position: absolute !important;
    top: -204px !important;
    height: 200px;
    min-width: 325px !important;
  }
  .closeHeader {
    display: inherit !important;
  }
}

.macroPopup .macroItem {
  cursor: pointer;
  padding: 12px;
}

.macroPopup .macroItem:hover {
  background-color: var(--grey-0) !important;
}

.upperLabels {
  display: flex;
}

.composerContainer {
  background: var(--white);
  border: 1px solid var(--off-light-grey);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 8px 8px 12px;
}

.composerContainer.disabled {
  background: var(--slate-05);
}

.inputContainer {
  display: flex;
  padding-bottom: 4px;
}

.inputContainer.providerInput {
  padding-bottom: 0;
}

.textboxContainer {
  flex-grow: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.buttonContainer.providerInput {
  margin-bottom: 0;
  padding-bottom: 0;
}

.buttonContainer > * {
  height: 32px;
}

.buttonContainer > *:not(:last-child) {
  margin-right: 4px;
}

:global(.ui.form) textarea.chatInput {
  padding: 8px 4px;
  border: none;
}

.upperLabelContent {
  flex-grow: 1;
}

.upperLabelButton {
  flex-grow: 0;
}

.bottomButtons {
  display: flex;
}

.bottomPills {
  display: flex;
}

.bottomPills > * {
  margin-right: 8px;
  margin-top: 9px;
  line-height: 24px;
}
.statusDropdown {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.disabled textarea.chatInput {
  background-color: var(--slate-05);
}

.disabled textarea.chatInput::placeholder {
  color: var(--slate-85);
}
