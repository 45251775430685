.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 430px;
  margin: 16px 0px 16px 0px;
}

.listItemDivider {
  height: 0.5px;
  width: 430px;
  background-color: var(--slate-15);
}

.sponsorName {
  padding-right: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: var(--slate);
}

.button {
  font-size: 14px;
  line-height: 135%;
  color: var(--slate);
  border-radius: 4px;
}

.activeButton {
  background-color: var(--slate);
  color: var(--white);
}

.dropdown {
  top: 8px;
  width: 195px;
  right: 0px;
  padding: 12px 0px 12px 0px;
  box-shadow: 0px 0.5px 1px rgba(45, 65, 71, 0.15), 0px 2px 8px rgba(58, 84, 91, 0.15);
  border-radius: 4px;
}

.toolRow {
  font-weight: normal;
  padding: 4px 14px 4px 24px;
  font-size: 14px;
  line-height: 150%;
}

.toolRow:hover,
.toolRow:active {
  background-color: var(--slate-15);
  cursor: pointer;
}

.chevron {
  margin: 0px 0px -3px 7.75px;
}

.sponsorList {
  margin-bottom: 70px;
}

.centeredModal {
  display: flex;
  flex-direction: column;
  max-height: 90%;
  top: 50%;
}
