.header {
  width: 100%;
  padding: 12px 24px;
}

.patientInfo {
  color: var(--grey-95);
  display: inline-block;
}

.patientInfoInner {
  display: flex;
}

.patientName,
.bullet,
.dob {
  line-height: 27px;
  font-size: 18px;
}

.bullet {
  padding: 0 12px;
}

.closeButton {
  display: inline-block;
  float: right;
  cursor: pointer;
  padding-top: 6px;
}

.img {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.content {
  height: calc(80vh);
  overflow-y: scroll;
}

:global(.content).modal {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column;
  padding: 0 !important;
}

.insuranceCardModal {
  width: 800px !important;
}
