.body.normal {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-85);
}

.body.small {
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-85);
}

.body.normal.link {
  color: var(--eden);
  border: none;
  text-decoration: none;
}

.caption {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
}

.caption.normal {
  color: var(--slate-85);
}

.caption.error {
  color: var(--razz-darken-20);
}

.header {
  color: var(--slate-darken-20);
}

.header.small {
  font-size: 16px;
  line-height: 22px;
}

.header.medium {
  font-size: 24px;
  line-height: 32px;
}

.header.medium.regular {
  font-weight: normal;
}

.kicker.header {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.list.kicker {
  font-weight: bold;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
}

.semibold {
  font-weight: var(--semibold);
}

.bold {
  font-weight: var(--bold);
}

.mobile.body {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
}

.warning {
  font-size: 12px;
  line-height: 16px;
  color: var(--razz-darken-20);
}
