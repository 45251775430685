.providerRow {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 6px 18px;
}

.providerRow:hover {
  background-color: var(--off-white-blue);
}

.providerRow:hover .assignButton {
  visibility: visible;
}

.provider {
  display: flex;
}

.name {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.assignButton {
  padding: 0;
  padding-left: 5px;
  visibility: hidden;
}

.assignButton:hover {
  background-color: transparent;
}

.checkCircle {
  border-radius: 100px;
  height: 14px;
  width: 14px;
  position: relative;
  top: 14px;
}

.stateCheckCircle {
  background-color: var(--pale-mint);
  z-index: 1;
  left: -9px;
}

.closeKnitCheckCircle {
  background-color: var(--grape-10);
  z-index: 0;
}

.icon {
  margin: 2.33px;
}

.circleContainer {
  width: 0;
}
