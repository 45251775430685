.visitInfoBar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  border-radius: 4px;
  font-weight: 700;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  color: var(--slate-darken-20);
  background-color: var(--slate-05);
  display: flex;
  align-items: center;
  gap: 5.5px;
}

.label.emphasis {
  background-color: var(--lemon-40);
}

.label.emphasis .icon {
  color: var(--slate-darken-20);
  stroke: var(--slate-darken-20);
  fill: var(--slate-darken-20);
}

.icon {
  color: var(--slate-55);
  stroke: var(--slate-55);
  fill: var(--slate-55);
}

.icon.chevron {
  width: 16px;
  height: 16px;
  stroke: none;
}

.icon.clipboard {
  width: 12px;
  height: 12px;
  stroke-width: 0.5;
}

.icon.healthcare {
  width: 12px;
  height: 12px;
  stroke: none;
}

.icon.minus {
  stroke: none;
}

.loading {
  cursor: not-allowed;
  margin: 0;
  height: 16px;
  width: 16px;
}
