.tab {
  padding-top: 10px;
}

.alertContainer {
  padding-top: 20px;
  margin: 0 18px;
}

.tab :global(.ui.pointing.secondary.menu) :global(.item) {
  margin: 0 6px;
}

.tab :global(.ui.pointing.secondary.menu) :global(.item):first-child {
  margin-left: 0px;
}

.sideButtonSection {
  display: flex;
  position: absolute;
  top: 16px;
  right: 16px;
}
