.header {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}

.headerIcon {
  vertical-align: -4px;
  margin-right: 12px;
}

.container {
  width: 450px;
  margin-top: 30px;
}

.card {
  margin-top: 32px;
  margin-bottom: 24px;
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  padding: 12px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  position: relative;
}

.label {
  font-weight: 600;
  color: var(--slate-darken-20);
}

.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 24px;
}

.button {
  padding: 12px 24px;
  width: auto;
}

.cancelButton,
.cancelButton:active {
  background: var(--slate-05);
  color: var(--slate-darken-20);
  margin-right: 8px;
}
