.button {
  border: none;
  padding: .625rem 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  font-weight: 600;
}

.big {
  height: 3rem;
  padding: 0;
  width: 20rem;
}

.small {
  height: 2.5rem;
  padding: .5rem 1rem;
}

.button div[role="progressbar"] {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.button div[role="progressbar"] svg {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.button svg {
  margin-right: .5rem;
  width: 1rem;
  height: 1rem;
}

.primary {
  /* Todo: use consistent gradient color from design instead of #8BDEAE. */
  color: var(--white);
}

.primary.success.pressed {
  background: var(--eden-darken-10);
}

.primary.destructive.pressed {
  background-color: var(--razz-darken-20);
}


.secondary {
  border: 2px solid var(--slate-15);
}

.primary.disabled {
  color: var(--slate-55);
  background-color: var(--slate-05);
  cursor: not-allowed;
}

.secondary.disabled {
  color: var(--slate-35);
}

.success {
  background-color: var(--eden);
}

.destructive {
  background-color: var(--razz);
}

.info {
  background: var(--slate-darken-20);
}

.secondary.success {
  color: var(--eden-darken-10);
  background-color: var(--white);
}

.secondary.destructive {
  color: var(--razz);
  background-color: var(--white);
}

.secondary.info {
  color: var(--white);
  border: none;
}

.secondary.pressed, .secondary.loading {
  background-color: var(--slate-05);
  border-color: var(--slate-05);
}

.secondary.info.pressed, .secondary.info.loading {
  background: var(--slate-darken-20);
}
