.labelContainer {
  margin: 0 0 4px 8px;
  width: min-content;
  white-space: nowrap;
}

.inlineContainer {
  display: inline-flex;
}

.label {
  border-radius: 100px;
  color: var(--slate-darken-20);
  padding: 4px 8px 4px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.sameTimeZone {
  background: var(--slate-05);
  border: 1px solid var(--slate-15);
}

.differentTimeZone {
  background: var(--grape-20);
}

.unknown {
  background: var(--slate-15);
  color: var(--slate-85);
}

.timeInfo {
  margin: 0 0 0 4px;
}
