.container {
  display: flex;
  padding: 16px;
  background: var(--nearer-white);
  border-radius: 6px;
}

.alertContainer {
  margin-right: 14px;
  display: flex;
  align-items: center;
}

.alert {
  font-style: normal;
  line-height: 18px;
  font-size: 11px;
  text-align: center;
  color: var(--white);
  display: inline-block;
  background-color: var(--grey-85);
  height: 18px;
  width: 18px;
  border-radius: 9px;
  min-width: 18px;
  min-height: 18px;
}

.text {
  line-height: 18px;
  font-size: 10px;
  letter-spacing: 0.0714286px;
  flex-grow: 0;
}
