.body {
  text-align: center;
  padding-top: 36vh;
}

@media (max-width: 1100px) {
  .body {
    margin-left: 306px;
  }
}
