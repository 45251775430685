.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
}

.categoryHeader {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--slate-darken-20);
}

.categoryHeader:first-child {
  margin-top: 2px;
}

.categoryHeader:not(:first-child) {
  margin-top: 12px;
}

.categoryContainer {
  background-color: var(--white);
  padding: 16px 4px 0;
  margin-right: 0;
  border-radius: 6px;
  margin-bottom: 8px;
}

.addonHeader {
  display: flex;
}

.addonHeaderLeft {
  margin-left: 8px;
  margin-right: 16px;
}

.addonHeaderRight {
  flex-grow: 1;
  padding: 0 4px;
}

.addonSubheader {
  display: flex;
}

.companyName {
  font-size: 16px;
  line-height: 21px;
  flex-grow: 1;
}

.addonCollapse {
  padding: 0;
  margin: 0;
  flex-shrink: 1;
  cursor: pointer;
}

.tagContainer {
  margin: 8px 0;
}

.tag {
  display: inline-block;
  margin: 4px 4px;
  background-color: var(--slate-05);
  border-radius: 15px;
  padding: 6px 8px;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
}

.bulletContainer {
  margin-left: 8px;
  padding-left: 12px;
  margin-top: 0;
}

.enrollmentUrl {
  margin-left: 2px;
  margin-top: 16px;
}

/* Override deep Link styles, since we can't pass in `className`; don't love this */
.enrollmentUrl a {
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--ocean) !important;
}

.sectionHeader {
  margin: 8px 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
}

.sectionBody {
  color: var(--slate-85);
  margin-left: 4px;
  margin-bottom: 8px;
}
