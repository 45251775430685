.container {
  display: flex;
  flex-direction: column;
}

.innerContainer {
  margin-left: 8px;
  display: inline-flex;
  flex-direction: row;
  line-height: 14px;
}

.fully-vaccinated circle {
  fill: var(--eden);
}

.first-dose-complete circle,
.second-dose-complete circle {
  fill: var(--lemon);
}

.not-vaccinated circle {
  fill: var(--slate-35);
}

.unknown-vaccine circle {
  display: none;
}

.status {
  margin-left: 4px;
  font-size: 12px;
  font-weight: var(--semibold);
  line-height: 16px;
}

.doses {
  position: absolute;
  cursor: auto;
  margin: 4px 0 0;
  padding: 16px;
  min-width: 212px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  font-size: 10px;
  line-height: 12px;
  z-index: 2000;
}

.doses {
  list-style-type: none;
}

.dose:not(:last-child) {
  margin-bottom: 12px;
}

.doseNum {
  text-transform: uppercase;
  font-weight: var(--semibold);
  margin-bottom: 6px;
  margin-left: 8px;
}

.doseDetails {
  background-color: var(--slate-05);
  color: var(--slate-85);
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
}

.detail {
  font-weight: var(--semibold);
  color: var(--slate-darken-20);
}

.detail:not(:last-child) {
  margin-bottom: 8px;
}
