.labelContainer {
  display: flex;
  flex-direction: column;
  width: min-content;
  white-space: nowrap;
}

.inlineContainer {
  display: inline-flex;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cleared,
.isolate,
.never-taken,
.incomplete {
  border-radius: 100px;
  color: var(--slate-darken-20);
  padding: 4px 8px 4px 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  display: flex;
}

.never-taken:hover {
  background: var(--ocean-40);
}

.cleared:hover {
  background: var(--apple-40);
}

.incomplete:hover {
  background: var(--slate-35);
}

.isolate:hover {
  background: var(--lemon-40);
}

.ellipse {
  padding-right: 4px;
}

.spinner {
  padding-left: 3.25px;
  padding-top: 1px;
}

.cleared {
  background: var(--apple-20);
}

.cleared circle {
  fill: var(--eden);
}

.incomplete {
  background: var(--slate-15);
}

.incomplete circle {
  fill: var(--slate);
}

.isolate {
  background: var(--lemon-20);
}

.isolate circle {
  fill: var(--lemon);
}

.never-taken {
  background: var(--ocean-20);
}

.never-taken circle {
  fill: var(--ocean);
}

.message {
  padding-right: 4px;
}

.chevronDown {
  margin-top: 1px;
}
