.avsCard {
  display: flex;
  flex-direction: column;
  width: 352px;
  border: 1px solid var(--slate-15);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-darken-20);
  padding: 16px;
  margin-top: 8px;
  cursor: pointer;
}

.infoBlock {
  display: flex;
  margin-bottom: 16px;
}

.infoText {
  margin-left: 12px;
  line-height: 16px;
}

.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summaryText {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: var(--slate);
  margin-left: 6px;
}
