@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  padding-left: 22px;
}

.label {
  font-style: normal;
  padding: 0 0 10px 22px;
  font-weight: bold;
  line-height: normal;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--grey-80);
  clear: both;
}

.upcomingLabel {
  color: var(--green-5);
  padding-bottom: 10px;
}

.checkedInLabel {
  display: flex;
  align-items: center;
  margin-top: 18px;
  padding-right: 4.67px;
}

.checkedInLabel svg {
  margin-left: 4.67px;
}

.label:first-child {
  padding-top: 16px;
}

.pastVisitContainer {
  position: relative;
  margin: 0 14px 0 24px;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid var(--slate-15);
}

.pastVisitContainer::before {
  content: ' ';
  position: absolute;
  top: 34px;
  transform: translateY(-50%);
  left: -11px;
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent var(--slate-15) transparent transparent;
  z-index: 1;
  width: 10px;
}

.pastVisitContainer::after {
  content: ' ';
  position: absolute;
  top: 34px;
  transform: translateY(-50%);
  left: -10px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--white) transparent transparent;
  z-index: 2;
}

.pastVisitGreenCircleIcon {
  position: absolute;
  fill: var(--apple);
  top: 35px;
  z-index: 1;
  height: 12px;
}

.visitContainerWrapper {
  position: relative;
  padding: 6px 0;
}

.line {
  position: absolute;
  stroke: var(--off-light-grey);
  height: 100%;
  width: 12px;
}

.lineBottom {
  display: none;
  top: 42px;
  position: absolute;
  stroke: var(--white);
  height: 100%;
  width: 12px;
}

.visitContainerWrapper:last-child > .lineBottom {
  display: initial;
}

.empty {
  background: var(off-near-white);
  border-radius: 6px;
  padding: 18px 24px;
  margin: 44px 18px;
}

.upcomingVisitContainer {
  border: 1px solid var(--off-light-grey);
  box-sizing: border-box;
  position: relative;
  height: 100%;
  margin: 0 14px 0 0;
  padding: 10px 16px;
  border-radius: 6px;
}

.moreApptsCaption {
  float: right;
  padding: 2px 16px 0 0;
  clear: both;
}

.loading {
  display: flex;
  background-color: var(--slate-15);
  height: 44px;
  margin-right: 14px;
  border-radius: 6px;
  align-items: center;
  padding-left: 12px;
}

.loading svg {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.loadingStatusContainer {
  margin-top: 32px;
}

.loadingStatusMessage {
  padding-left: 8px;
}

.error {
  display: flex;
  align-items: center;
  background-color: var(--razz-20);
  border-radius: 6px;
  height: 44px;
  padding-left: 9px;
  margin-right: 14px;
}

.errorReload {
  cursor: pointer;
  margin-left: 8px;
  height: 20px;
}
