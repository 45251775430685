.details {
  display: flex;
  width: 100%;
  margin: 6px;
}

.pharmacyName {
  line-height: 21px;
  font-size: 18px;
  letter-spacing: 0.125px;
  color: var(--grey-95);
  margin-bottom: 8px;
  margin-right: 24px;
}

.panel {
  width: 50%;
  min-width: 200px;
}

.detailsContainer {
  line-height: 21px;
  font-size: 13px;
  color: var(--grey-95);
  margin: 0;
}

.detailsContainer:first-child {
  padding-top: 0;
  padding-bottom: 12px;
  border-bottom: solid 1px;
  border-color: var(--light-steel);
}

.detailsContainer:last-child {
  padding-top: 12px;
  padding-bottom: 0px;
}
