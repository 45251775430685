.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

.chats {
  flex: 1 1 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
}

.column {
  width: 50%;
  flex: 1 1 50%;
  padding: 0;
  margin: 0;
}
.columnClosedMobile {
  composes: column;
}
.columnClosedMobile {
  display: none;
}
@media (min-width: 768px) {
  .columnClosedMobile {
    display: block;
  }
}

.mainChatContainer {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chatHolder {
  flex: 1 1 100%;
  height: 100%;
  background-color: var(--off-white-blue);
  box-shadow: inset 8px 0 7px -8px rgba(34, 36, 38, 0.12);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  border-left: 1px solid var(--grey-10);
}

.segContainer {
  height: 50%;
  max-height: 50%;
  padding: 0 !important;
}

.segContainer :global(.active.tab) {
  padding-top: 0;
  padding-bottom: 0;
}

.segContainer > div {
  height: 100%;
}

.segContainer:first-child > div {
  overflow-y: hidden;
}

.segContainer:first-child > div:hover {
  overflow-y: overlay;
}

@media (max-width: 767px) {
  .segContainer:first-child > div:hover,
  .segContainer:first-child > div {
    overflow-y: scroll;
  }
}

.container :global(.ui.pointing.secondary.menu) {
  border-bottom: 0;
  padding-left: 24px;
}

.container :global(.ui.pointing.secondary.menu) :global(.item) {
  padding: 2px 0;
  margin: 0 12px;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: var(--grey-60);
  font-weight: normal;
}

.container :global(.ui.pointing.secondary.menu) :global(.item):first-child {
  margin-left: 14px;
}

.container :global(.ui.pointing.secondary.menu) :global(.item.active) {
  color: var(--eden);
  border-color: var(--eden);
  line-height: normal;
  font-size: 14px;
  text-align: center;
}
