.progress {
  width: 100%;
  background-color: var(--slate-55);
  border-radius: 4px;
  height: 0.5em;
  margin: 0 0 0.5em 0;
  position: relative;
}

.progress::after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  border-radius: 4px;
  animation: active 2s ease infinite;
}

@keyframes active {
  0% {
    opacity: 0.3;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}

.progressContainer {
  margin-bottom: 0.5em;
  position: relative;
  padding: 0;
  box-shadow: none;
  max-width: 100%;
  border-radius: 4px;
}
