.container {
}

.btn {
  min-height: 100px;
  padding: 0;
  border: none;
  background: none;
}

.btn:focus {
  outline: none;
}

.btn:hover {
  cursor: pointer;
}

.img {
  height: 100px;
  max-width: 100%;
}

.expand {
  max-width: 100%;
  height: auto;
}
