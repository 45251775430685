.container {
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  box-shadow: inset 8px 0 7px -8px rgba(34, 36, 38, 0.12);
  background-color: var(--near-white);
  border-bottom: 1px var(--grey-10) solid;
  flex-direction: column;
  cursor: pointer;
}

.infoHeader {
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
}

.headerPills {
  display: inline-flex;
}

.patientName {
  padding-top: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;

  color: var(--secondary);
  display: inline-block;
}

.imgWrapper {
  border-radius: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  border: none;
  vertical-align: top;
  display: inline-block;
  background-color: var(--grey-50);
  padding: 0;
  height: 48px;
  width: 48px;
  overflow: hidden;
  margin: 0 10px;
}

.imgStyle {
  border-radius: 100px;
  border: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.profileWrapper {
  flex-grow: 1;
  padding: 14px 25px 8px 25px;
  border: solid;
  border-color: var(--off-light-grey);
  border-width: 1px 0 0 0;
}

.flexContainer {
  display: flex;
  flex-grow: 2;
  margin-bottom: 8px;
}

.additionalInfoContainer {
  display: flex;
  flex-grow: 2;
  margin-bottom: 8px;
  margin-left: 80px;
  flex-wrap: wrap;
}

.additionalInfoContainer > div:nth-child(n + 2) {
  border-color: var(--slate-25);
  border-width: 0 0 0 1px;
  border-style: solid;
  padding-left: 14px;
  margin: 3px 0 3px 14px;
}

.greyspaceGroup {
  margin: 9px 0;
  align-items: center;
  display: flex;
  flex-grow: 2;
}

.arrow {
}

.assignArrow {
  composes: arrow;
  display: inline-block;
  padding: 0 0 0 8px;
}

.expandArrow {
  composes: arrow;
  cursor: pointer;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.assign {
  float: right;
  display: flex;
  flex-direction: row;
}

.eventLogButton {
  width: 110px;
  margin-right: 6px;
}

.assignText {
  display: inline-block;
  padding-top: 2px;
}

.assignButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 18px;
  width: 100%;
}

.patientSummary {
  width: 100%;
}

.summary {
  font-size: 14px;
  line-height: 21px;

  color: var(--slate);
  padding-left: 1.5px;
}

.summary > span {
  border-color: var(--slate-25);
  border-width: 0 1px 0 0;
  border-style: solid;
  padding-right: 8px;
  margin-right: 8px;
}

.summary > span:nth-child(n + 1) {
  margin-right: 2px;
}

.summary .ageSexContainer {
  margin-left: 6px;
}

.summary .hoverableContainer {
  margin-right: -8px;
}

.patientDetails {
  display: flex;
  width: 100%;
  color: var(--slate);
}

@media screen and (max-width: 1440px) {
  .patientDetails {
    flex-direction: column;
  }
}

.row {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  padding-bottom: 6px;
}

.row > :first-child {
  width: 13px;
  min-width: 13px;
  margin-right: 7px;
  margin-top: 3px;
}
