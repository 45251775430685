.visitProvider {
  position: relative;
  padding: 12px 0;
  border: 1px solid var(--slate-15);
  border-left-width: 0;
  border-right-width: 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headshot {
  margin: 0 16px 0 6px;
}

.headshotImg {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.body {
  color: var(--slate-darken-20);
  font-weight: 600;
}
