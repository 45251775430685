.event {
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  text-align: center;

  color: var(--off-slate);
  text-align: center;
  padding: 4px 0;
}

:not(.event) + .event {
  padding-top: 24px;
}

.event + :not(.event) {
  padding-top: 24px !important; /* this collides with the padding defined in ChatMessage.module.css :( */
}

.videoCallCard,
.videoCallCardDone,
.videoCallCardMock {
  border: 1px solid var(--off-light-grey);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

.videoCallCardDone {
  display: flex;
}

.videoCallCardMock {
  max-width: unset;
}

.videoRow {
  display: flex;
  width: 100%;
}

.videoCallCard,
.videoCallCardMock {
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.videoCallCardDone {
  background: var(--off-light-grey);
}

.headshot,
.videoIcon,
.callButtonSpacer {
  width: 40px;
}

.videoCallInfo,
.videoCallButton {
  padding: 0 12px;
  flex-grow: 2;
}

.videoCallHeader,
.videoCallHeaderMock,
.videoCallHeaderDone {
  font-size: 16px;
  line-height: 120%;
  color: var(--secondary);
  padding-bottom: 2px;
}

.videoCallHeaderDone {
  padding-top: 10px;
}

.videoCallHeaderMock {
  padding-bottom: 6px;
}

.videoCallMetadata,
.videoCallMetadataDone,
.videoCallMetadataMock {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: var(--slate-85);
}

.videoCallMetadata {
  padding-bottom: 16px;
}

.placeholder {
  padding-bottom: 12px;
}

.callCardOuterContainer {
  margin: 12px 12px 12px 0;
  padding-bottom: 12px;
}

.callCardOuterContainer:first-child {
  margin-top: 0;
}

@media (max-width: 768px) {
  .callButtonSpacer {
    width: 0;
  }

  .videoCallButton {
    padding: 0;
  }
}

.screenerCompleted {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--slate-15);
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 10px;
  max-width: 480px;
}
.screenerCompletedHeader {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.screenerCompletedHeaderLeft {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.screenerCompletedHeaderLeftAlignTop {
  composes: screenerCompletedHeaderLeft;
  align-items: flex-start;
}
.screenerHeader {
  display: flex;
  flex-direction: column;
}
.screenerHeaderTitle {
  color: var(--slate-darken-20);
  max-width: 290px;
  line-height: 1rem;
}
.screenerHeaderSubtitle {
  color: var(--slate);
  margin-top: 7px;
}
.screenerHeaderChevron {
  fill: var(--slate-55);
}
.screenerCompletedIcon {
  display: flex;
  color: var(--eden);
  margin-right: 12px;
}
.screenerCompletedIconPassed {
  composes: screenerCompletedIcon;
  color: var(--eden);
}
.screenerCompletedIconNotPassed {
  composes: screenerCompletedIcon;
  color: var(--lemon);
}

.emphasis {
  font-weight: 600;
}

.screenerExpandedArea {
  border-top: 1px solid var(--slate-15);
  padding-top: 12px;
  padding-bottom: 16px;
}
.screenerTitle {
  color: var(--slate-darken-20);
  font-weight: 600;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.screenerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
}

.screenerQuestions :not(:first-child):not(:last-child) {
  margin: 8px 0;
}

.screenerRowQuestion {
  color: var(--slate-85);
  line-height: 135%;
}

.screenerRowNumber {
  font-weight: 600;
  color: var(--slate-darken-20);
}

.screenerRowStateStatusList {
  color: var(--slate-85);
  line-height: 135%;
  margin: 0;
}

.screenerRowStateStatus {
  color: var(--slate-85);
  line-height: 135%;
}

.screenerRowStatePolicyVersion {
  color: var(--slate-85);
  line-height: 135%;
}

.screenerRowAnswer {
  font-weight: 600;
  line-height: 135%;
  min-width: 40px;
  text-align: right;
  color: var(--slate-darken-20);
}

.screenerRowAnswerIsolate {
  composes: screenerRowAnswer;
  color: var(--lemon-darken-20);
}

.requestVideoVisitContainer {
  border: 1px solid var(--slate-15);
  box-sizing: border-box;
  border-radius: 6px;
}

.requestVideoVisitContainerActive {
  background: var(--white);
  max-width: 480px;
  color: var(--slate-darken-20);
}
.requestVideoVisitContainerActive path {
  fill: var(--eden);
}

.requestVideoVisitContainerExpired {
  background: var(--slate-05);
  max-width: 342px;
  color: var(--slate-55);
}
.requestVideoVisitContainerExpired path {
  fill: var(--slate-55);
}

.videoVisitIcon {
  margin-right: 12px;
}

.requestVideoVisitExpirationNotice {
  background: var(--slate-05);
  border-radius: 0 0 6px 6px;
  color: var(--slate-85);
  font-size: 11px;
  border-top: 1px solid var(--slate-15);
  line-height: 135%;
  padding: 10px 16px;
  width: 100%;
}

.requestVideoVisitHeader {
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

.insuranceSubmitted {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--slate-15);
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 10px;
  max-width: 480px;
}

.insuranceSubmittedHeader {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.insuranceSubmittedHeaderLeft {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.insuranceSubmittedTitle {
  color: var(--slate-darken-20);
  max-width: 290px;
  line-height: 1rem;
}

.insuranceSubmittedIcon {
  display: flex;
  color: var(--eden);
  margin-right: 12px;
}
