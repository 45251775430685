.careteamName {
  color: var(--slate-darken-20);
  font-weight: 600;
}

.usualProviderName {
  margin-top: 5px;
}

.noUsualProvider {
  color: var(--slate-55);
  margin-top: 5px;
}
