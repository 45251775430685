.header {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.headerText {
  flex-grow: 2;
}

.close:hover {
  cursor: pointer;
}

.modalInner {
  display: inline-block;
  padding: 15px;
}

.buttonRow {
  float: right;
  margin-bottom: 4px;
}

.description {
  margin-bottom: 24px;
}

.buttonRow :first-child {
  margin-right: 12px;
}
