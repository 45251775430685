@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
  color: var(--text-dark);
}

/* overriding semantic ui */
.ui.menu,
button {
  font-family: 'Open Sans', sans-serif !important;
}

/* Changes Semantic UI default color for placholders */
.ui.placeholder,
.ui.placeholder.image.header:after,
.ui.placeholder .line,
.ui.placeholder .line:after,
.ui.placeholder > :before {
  background-color: #f5f5f5;
}

:root {
  --semibold: 600;
  --bold: 800;

  /* COLORS */
  --action-primary: #5c60d4;

  --apple: #6ed69a;
  --apple-10: #f0fbf5;
  --apple-20: #e2f7eb;
  --apple-40: #c5efd7;

  --eden: #14b87c;
  --eden-darken-10: #12a670;
  --eden-darken-20: #008051;

  --grape: #5061fa;
  --grape-10: #edefff;
  --grape-20: #dcdffe;

  --grape-darken-20: #3c30c2;

  --lemon: #ffcc25;
  --lemon-10: #fffae9;
  --lemon-20: #fff5d3;
  --lemon-40: #ffeba8;
  --lemon-60: #ffe07c;

  --lemon-darken-20: #ba8e00;

  --ocean: #0391f4;
  --ocean-20: #cde9fd;
  --ocean-40: #9ad3fb;

  --razz: #f8506f;
  --razz-10: #feedf1;
  --razz-20: #fedce2;
  --razz-darken-20: #b42641;

  --slate: #3a545b;
  --slate-05: #f5f6f7;
  --slate-15: #e1e5e6;
  --slate-25: #ced4d6;
  --slate-35: #bac3c6;
  --slate-45: #a6b2b5;
  --slate-55: #93a1a5;
  --slate-85: #586e74;
  --slate-darken-20: #2e4349;

  --off-light-grey: #e9eef0;

  --google-blue: #4285f4;

  --white: #ffffff;

  --accent-e1: #e6f4fe;

  /* DEPRECATED COLORS */
  --primary-dark: #028c61;
  --secondary: #2d4147;
  --secondary-900: #1d2c31;
  --grey-95: #181818;
  --grey-84: #4b4b4b;
  --grey-85: #4c4c4c;
  --grey-80: #555555;
  --grey-60: #777777;
  --grey-50: #888888;
  --grey-30: #aaaaaa;
  --grey-20: #bbbbbb;
  --grey-15: #c4c4c4;
  --grey-10: #cccccc;
  --grey-05: #dddddd;
  --grey-03: #e8e8e8;
  --grey-0: #eeeeee;
  --near-white: #f5f5f5;
  --nearer-white: #f6f6f6;
  --text-dark: rgba(0, 0, 0, 0.9);
  --text-muted: rgba(0, 0, 0, 0.6);

  --eden-gradient: linear-gradient(173.12deg, #14b87c 0%, #a8e6c2 100%);
  --ocean-gradient: linear-gradient(135deg, #0391f4 0%, #68bdf8 100%);
  --lemon-gradient: linear-gradient(135deg, #ffcc25 0%, #ffeba8 100%);
  --razz-gradient: linear-gradient(135deg, #f8506f 0%, #fb96a9 100%);

  --red-4: #f15d6f;
  --green-5: #13b378;
  --grey-4: #9ca9ad;

  /* DEPRECATED COLORS WITH ONLY ONE USE */
  --off-near-white: #f4f4f5;
  --off-white-blue: #f4f6f7;
  --off-slate: #6b7f84;
  --clifford-red: #b81435;
  --light-steel: #dddede;
  --pale-sand: #fff6c7;
  --unhappy-yellow: #f1c737;
  --aspen-yellow: #f3c633;
  --pale-pink: #ffd4d4;
  --pale-mint: #bff3da;
  --slime-green: #1db981;
  --watermelon-red: #fa5266;
  --pink-red: #ff486b;
  --food-dye-green: #1bb978;
  --off-jade: #18a674;
  --light-sky-blue: #179feb;
  --sky-blue: #0070c1;
  --dark-sky-blue: #0061d3;
}

.focus-ring {
  outline: 2px solid var(--eden) !important;
}
