@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  height: 56px;
  width: 56px;
  stop-color: var(--eden);
}

.firstStop {
  stop-color: var(--eden);
}

.secondStop {
  stop-color: var(--gradient-light-green);
}
