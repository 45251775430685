.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 520px;
  height: 640px;
  background-color: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  outline: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topNav {
  z-index: 1010;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  padding: 24px 33px;
  box-shadow: 0px 2px 4px rgba(46, 67, 73, 0.08);
}

.fileIcon {
  padding-top: 4px;
  height: 32px;
}

.title {
  margin-left: 15px;
}

.xIcon {
  margin-left: auto;
  height: 32px;
  padding-top: 4px;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 24px 33px;
}

.requiredText {
  margin-bottom: 16px;
}

.commonSelect {
  width: 318px;
}

.day {
  width: 254px;
}

.fullWidth {
  width: 100%;
}

.addressGrid {
  display: grid;
  grid-template-columns: 320px 118px;
  grid-column-gap: 18px;
  grid-template-rows: 80px;
}

.cityStateZipGrid {
  display: grid;
  grid-template-columns: 192px 110px 118px;
  grid-column-gap: 18px;
  grid-template-rows: 80px;
}

.selectList {
  max-height: 400px;
}

.shortSelectList {
  max-height: 200px;
}

/* Remove spinners from number input in Chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Same for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.schedulingProvider {
  width: 318px;
  margin-bottom: 8px;
}

.schedulingProviderCaption {
  width: 318px;
  margin-bottom: 24px;
}

.instructionsCaption {
  margin-bottom: 26px;
}

.plusBox {
  margin-right: 8px;
}

.bottomNav {
  z-index: 1010;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 24px;
  height: 76px;
  box-shadow: 0px -2px 4px rgba(46, 67, 73, 0.08);
}

.button {
  width: 151px;
  height: 40px;
}

.cancel {
  margin-right: 8px;
  background: var(--slate-05);
  color: var(--slate-darken-20);
}

.cancel:active,
.cancel:focus {
  background: var(--slate-25);
}

.sponsorName {
  width: 318px;
  background-color: var(--slate-05);
  color: var(--slate-darken-20);
  font-size: 14px;
  line-height: 20px;
  border: 1px solid var(--slate-05);
  border-radius: 4px;
  padding: 10px 12px;
}

.startDateGrid,
.startDateGridError {
  display: grid;
  grid-template-columns: 320px 118px;
  grid-column-gap: 18px;
  grid-template-rows: 80px;
}

.startDateGridError {
  grid-template-rows: 96px;
}

.instructions {
  height: 135px;
}
