.menuColumn {
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: var(--slate-05);
}

.menu {
  margin-top: 58px;
}

.logoutButton {
  position: absolute;
  padding-left: 36px;
  bottom: 24px;
}

.contentColumn {
  padding: 52px 12px 12px 48px;
  margin-left: 200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.version {
  padding: 24px 0;
}

.settingsView {
  width: 100%;
  overflow-y: scroll;
}

.column {
  width: 52.5%;
}
