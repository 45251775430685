.mainSidebarRoomItem {
  --active-border-right: 5px;
}

.mainSidebarRoomItem {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--slate-darken-20);
  border-top: 1px solid var(--off-light-grey);
  padding: 0.75rem 0 0.75rem 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mainSidebarRoomItem.legacy {
  padding: 5px 0 5px 10px;
}

.mainSidebarRoomItem:last-child {
  border-bottom: 1px solid var(--off-light-grey) !important;
}

.mainSidebarRoomItem.archived {
  border-top: none;
}

.mainSidebarRoomItem.inactive {
  padding: 4px 0 4px 10px !important;
}

.mainSidebarRoomItem.archived .roomItemInfoAndProfile {
  margin-left: 17px;
}

.selected,
.selected:hover {
  font-weight: 400 !important;
  color: var(--slate) !important;
  background-color: var(--off-white-blue) !important;
}

.mainSidebarRoomItem:hover {
  background-color: var(--off-white-blue) !important;
}

.mainSidebarRoomItem .roomItemInfoAndProfile {
  display: flex;
  justify-content: space-between;
}

.mainSidebarRoomItem.legacy .roomItemInfoAndProfile {
  padding-right: 10px;
}

.mainSidebarRoomItem:global(.active.item) .roomItemInfoAndProfile {
  padding-right: calc(20px - var(--active-border-right));
}

.mainSidebarRoomItem.legacy .icon {
  margin-right: 9px;
  align-self: center;
}

.mainSidebarRoomItem .icon .pencilIcon {
  display: inline-block;
}

.label.open {
  color: var(--clifford-red) !important;
}

.label.pending {
  color: var(--primary-dark) !important;
}

.label.archived {
  color: var(--primary-dark) !important;
}

.licensingIndicator {
  margin: 0 -1px;
}

.timestamp {
  font-size: 12px;
  margin-top: 4px;
  color: var(--slate);
  line-height: 14px;
}

.unread {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  color: var(--secondary);
}

.truncatedText {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roomInfo {
  flex-grow: 1;
  padding-right: 0.5rem;
}

.legacy .roomInfo {
  padding-right: 0;
}

.chatTagRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  line-height: 1rem;
}

.chatTags {
  margin-left: auto;
  display: flex;
  gap: 0.25rem;
}

.icon {
  margin-right: 0.5rem;
  align-self: flex-start;
}
