.sponsorName {
  font-weight: normal;
  font-size: 16px;
  line-height: 163.42%;
  color: var(--slate);
  padding-bottom: 14px;
}

.selectFile {
  padding-bottom: 24px;
}

.error {
  padding-top: 14px;
  padding-bottom: 14px;
  color: var(--razz-darken-20);
  overflow-y: auto;
  white-space: pre-line;
}

table {
  border: 1px solid var(--slate-25);
  border-collapse: collapse;
  display: block;
  overflow-y: auto;
  min-height: 0;
}

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}

table td,
table th {
  border: 1px solid var(--slate-25);
  padding-left: 5px;
  padding-right: 5px;
}

table th {
  white-space: nowrap;
}

.noLineBreak {
  white-space: nowrap;
}

.lineBreaks {
  white-space: pre-line;
}

.notesColumn {
  min-width: 120px;
}

.spinnerBox {
  height: 24px;
  width: 24px;
  margin: auto;
}
