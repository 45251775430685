.macro {
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 20px;
}
.plusMinusContainer {
  margin-right: 14px;
}

.detailsAndEdit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.macro .editButton {
  visibility: hidden;
  text-align: right;
}
.macro:hover {
  background-color: var(--off-white-blue);
}

.macro:hover .editButton {
  visibility: visible;
}

.title {
  font-weight: 600;
}
.textExpanded {
  margin-top: 12px;
}
