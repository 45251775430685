.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.noPopups {
  width: 532px;
  height: 60px;
  border-radius: 64px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--slate-85);
  background-color: var(--apple-20);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wind {
  margin-bottom: 3px;
}

.topNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
}

.spacer {
  flex-grow: 1;
}

.newButton,
.newButton:active,
.newButton:focus {
  width: 157px;
  height: 40px;
}

.list {
  margin-bottom: 48px;
}

.listTitle {
  padding-bottom: 12px;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  color: var(--slate);
}

.listItem {
  display: flex;
  flex-direction: row;
  width: 532px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid var(--slate-15);
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-85);
  cursor: pointer;
}

.listItem.selected,
.listItem:hover {
  box-shadow: 0px 2px 4px rgba(46, 67, 73, 0.16);
}

.listItem.pastPopup {
  background-color: var(--slate-05);
  border: none;
}

.calendar {
  grid-column: 1;
  grid-row: 1 / 2;
}

.listItemDetails {
  display: grid;
  grid-template-columns: 240px 180px;
  grid-template-rows: 20px auto-fit;
  grid-column-gap: 32px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 16px;
}

.overflowEllipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sponsorName {
  grid-column: 1;
  grid-row: 1;
  font-weight: bold;
  color: var(--slate-darken-20);
}

.times {
  grid-column: 2;
  grid-row: 1;
  font-weight: bold;
  color: var(--slate-darken-20);
}

.address {
  grid-column: 1;
  grid-row: 2;
}

.popupType {
  grid-column: 2;
  grid-row: 2;
}

.listItemHeading {
  font-weight: bold;
  color: var(--slate-darken-20);
}

.tabContainer :global(.ui.pointing.secondary.menu) {
  border-bottom: 0;
  min-height: min-content;
  margin-bottom: 24px;
}

.tabContainer :global(.ui.pointing.secondary.menu) :global(.item) {
  padding: 2px 0;
  margin: 0 20px;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  color: var(--grey-60);
  font-weight: normal;
}

.tabContainer :global(.ui.pointing.secondary.menu) :global(.item):first-child {
  margin-left: 0;
}

.tabContainer :global(.ui.pointing.secondary.menu) :global(.item.active) {
  color: var(--eden);
  border-color: var(--eden);
  line-height: normal;
  font-size: 14px;
  text-align: center;
}
