.sidebar {
  width: 306px;
  min-width: 306px;
  max-width: 306px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.item {
  margin-bottom: 10px;
}

.item .header {
  margin: 12px 12px 0.5em;
  font-size: 1em;
  font-weight: 700;
  font-style: normal;
  color: var(--slate);
}

.sidebar.localProdMode {
  background-color: var(--lemon-20) !important;
}

.logo {
  border-radius: 5px;
  margin-right: 5px;
  float: left;
}

.version {
  font-size: 0.8em;
}

.search {
  text-align: center;
}

.multiFilterView {
  margin-left: 12px;
  margin-top: 4px;
  margin-bottom: 6px;
}

.patients {
  overflow: hidden;
}

.patients:hover {
  overflow-y: overlay;
}

.patients :global(.header) {
  font-style: normal;
  padding-top: 12px;
  color: var(--slate);
}

.sectionTitle {
  font-size: 11px !important;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold !important;
}

.sectionTitle:hover {
  cursor: pointer;
}

.titleArrow {
  display: inline-block;
  margin-left: 4px;
  transform: translateY(-1px);
}

.titleArrowClosed {
  transform: rotate(270deg) translateY(-2px) translateX(-1px);
}

.unassignedHeader {
  display: inline-block;
  margin-bottom: 2px;
}

.assignedFilter,
.routedFilter {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 10px;
}

.stateFilter {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-85);
}

.stateFilterCheckbox {
  display: inline;
  margin-left: 4px;
  vertical-align: -4px;
}

.stateFilterCheckbox:hover {
  cursor: pointer;
}

.count {
  margin-left: 4px;
}
