.modal {
  left: 500px;
}

.header {
  display: flex;
  align-items: center;
}

.editIcon {
  margin-right: 10px;
}

.instructions {
  margin-top: 6px;
  color: var(--slate-85);
}

.topField {
  margin-top: 30px;
}

.labelWithLink {
  display: flex;
  justify-content: space-between;
}

.labelWithLink label {
  font-size: 12px;
  font-weight: 600;
  color: var(--slate);
}

.required::after {
  content: ' *';
  color: var(--razz-darken-20);
}

.buttonLink {
  margin-bottom: 8px;
  border: 0;
  font-size: 12px;
  color: var(--eden-darken-10);
  background: none;
}

.field input {
  width: 100%;
}

.field input:disabled {
  border: 1px solid var(--slate-15);
}

.field input::placeholder {
  color: var(--slate-55);
}

.field label + div {
  margin-bottom: 6px;
}

.fieldNote {
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 1;
  font-size: 12px;
  font-weight: 400;
  color: var(--slate-85);
}

.fieldError {
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: var(--razz-darken-20);
}

.selectorWrapper {
  margin-bottom: 26px;
}

.selector {
  color: var(--slate-darken-20);
}

.selector.placeholder {
  color: var(--slate-55);
}

.radioGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.textArea {
  margin: 0;
  padding: 8px 12px;
  height: 92px;
  border-radius: 6px;
  line-height: 1.35;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--slate-darken-20);
}

.textArea::placeholder {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--slate-55);
}

.error {
  margin-top: 24px;
  font-size: 14px;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.cancelBtn {
  width: 151px;
  height: 48px;
  margin-right: 8px;
  background: var(--slate-05);
  color: var(--slate-darken-20);
}

.saveBtn {
  width: 186px;
  height: 48px;
}

.btn:disabled {
  background: var(--slate-05);
  color: var(--slate-55);
}

div.modalStyleOverride {
  top: 50%;
}
