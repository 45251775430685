.publicContainer {
  height: 100%;
  background: var(--near-white);
}

.public {
  height: 100%;
}

.publicContent {
  max-width: 768px;
}

.publicContent svg {
  margin-bottom: 14px;
}

.publicHeader {
  color: var(--slate-darken-20);
  padding-bottom: 12px;
  border-bottom: 1px solid var(--slate-15);
  font-weight: 500;
}

.menuBarItem {
  color: var(--slate-55) !important;
  font-size: 16px;
}

.menuBarItem:hover {
  color: var(--slate-85) !important;
  font-size: 16px;
}

.menuBarItemActive {
  color: var(--slate-darken-20) !important;
  font-size: 16px;
}

.panel {
  background: white;
  border-radius: 8px;
  border-top: 4px solid var(--slate-darken-20);
  box-shadow: 0 0 4px 2px rgba(100, 100, 100, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 24px;
  padding-top: 16px;
  margin: 0 12px;
}

.acceptableUse {
  display: flex;
  padding: 30px;
  font-size: 12px;
  line-height: 135%;
  align-items: center;
  text-align: left;
  color: var(--text-muted);
}
