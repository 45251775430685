.welcomeCard {
  margin: 12px 12px 12px 0;
  border: 1px solid var(--off-light-grey);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.welcomeCard:first-child {
  margin-top: 0;
}

.buttonContainer {
  margin-top: 16px;
}

.buttonContainer button {
  width: 100%;
}
