:global(body),
:global(body div#root) {
  height: 100%;
}

.main {
  height: 100%;
}

.mainContent {
  height: 100%;
  width: 100%;
}

:local .mainContent > :global(div) {
  height: 100%;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 1;
  display: flex;
  width: 100%;
  height: 0;
}
