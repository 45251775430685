.search {
  display: flex;
  position: relative;
  padding: 0 18px 12px 0;
}

.searchInput {
  left: -18px;
  width: 282px;
}

.search :global(.ui.icon.input) > input {
  background: var(--off-white-blue);
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 140%;
  border: none;
  width: 100%;
  padding: 8px 18px;
}

.search :global(.ui.icon.input) > input::placeholder {
  color: var(--grey-4) !important;
}

.search :global(.search.icon) {
  display: none;
}

.searchIcon {
  left: 8px;
  padding-top: 8px;
  z-index: 1000;
  position: relative;
}

.loader {
  left: 232px;
  position: absolute;
  top: -5px;
}
