.button:focus {
  background-color: rgba(0, 0, 0, 0);
}

.button:active {
  background-color: var(--slate);
}

.button:hover {
  background-color: var(--off-light-grey);
}

.button path,
.button.disabled path {
  fill: var(--slate-25);
}

.button:active path {
  fill: var(--white);
}

.button:hover path {
  fill: var(--slate);
}

.button.disabled {
  background-color: rgba(0, 0, 0, 0);
  cursor: default;
}

.button.disabled:hover path,
.button.disabled:active path {
  fill: var(--slate-25);
}
