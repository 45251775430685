.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.container {
  width: 560px;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  box-shadow: -4px 2px 32px rgba(46, 67, 73, 0.16);
  border-radius: 16px 0px 0px 0px;
  background: var(--white);
  outline: none;
  display: flex;
  flex-direction: column;
}

.topNav {
  height: 88px;
  padding: 37px 45px;
}

.popupState {
  margin-top: 8px;
  margin-bottom: 8px;
}

.sponsorRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  width: 327px;
  height: auto;
  margin-right: 32px;
  margin-bottom: 32px;
}

.editButton,
.editButton:active,
.editButton:focus {
  width: 122px;
  height: 44px;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.details {
  display: grid;
  grid-template-columns: 124px 333px;
  grid-auto-rows: minmax(20px, auto);
  row-gap: 16px;
}

.detailsLabel {
  font-size: 14px;
  line-height: 16px;
  color: var(--slate-85);
}

.detailsValue {
  font-size: 14px;
  line-heght: 20px;
  color: var(--slate-darken-20);
  font-weight: 600;
}

.light {
  color: var(--slate-85);
  font-weight: normal;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 56px;
}

.deleteEvent {
  cursor: pointer;
}

.activate,
.activate:active,
.activate:focus {
  width: 122px;
  height: 40px;
}
