.trashIcon {
  vertical-align: -2px;
  margin-right: 12px;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.deletedMessage {
  background: var(--slate-05);
  padding: 12px;
  margin-top: 32px;
  margin-bottom: 24px;
  overflow-y: auto;
  max-height: 350px;
  width: 455px;
}

.confirmBtn {
  width: 151px;
  height: 44px;
}

.confirmDeleteBtn {
  background: var(--razz-gradient);
}

.confirmDeleteBtn:active,
.confirmDeleteBtn:focus {
  background: var(--razz);
}

.cancelDeleteBtn {
  background: var(--slate-05);
  color: var(--slate-darken-20);
  margin-right: 16px;
}

.cancelDeleteBtn:active,
.cancelDeleteBtn:focus {
  background: var(--slate-25);
}

.deletedMessage button {
  pointer-events: none;
}

.deletedMessage img {
  max-width: 100%;
  height: auto;
  max-height: 300px;
}

.label {
  font-size: 14px;
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.deletedMessage .btn {
  pointer-events: none;
}

.comment:hover {
  background-color: inherit !important;
}

.error {
  margin-top: 16px;
  font-size: 12px;
}
