.container {
  display: grid;
  height: 44px;
  width: 40px;
  grid-template-areas: 'inner-div';
}

.inner {
  grid-area: inner-div;
}

.daysNumber,
.recurringIcon {
  width: 30px;
  text-align: center;
}

.recurringIcon {
  padding-top: 25px;
}

.daysNumber {
  font-weight: bold;
  color: var(--slate);
  padding-top: 21px;
}
