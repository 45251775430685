.placeholder {
  background: var(--off-light-grey);
  border-radius: 100px;
  height: 12px;
  min-height: 12px;
  max-height: 12px;

  width: 50%;
  min-width: 50%;
  max-width: 50%;
}

.wide.placeholder {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}

.full.placeholder {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
