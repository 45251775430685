.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  width: 520px;
  height: 424px;
  background-color: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  outline: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  margin-bottom: 8px;
}

.xIcon {
  position: fixed;
  top: 37px;
  right: 37px;
  height: 24px;
  width: 24px;
}

.xIcon:hover {
  cursor: pointer;
}

.usualProviderMessage {
  margin-top: 8px;
  margin-bottom: 54px;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
