.ellipsisIcon {
  width: 16px;
  height: 16px;
}

.ellipsisMenu {
  height: 20px;
}

.ellipsisMenuTrigger {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipsisMenuTrigger:hover {
  cursor: pointer;
  background-color: var(--slate-15);
}

.ellipsisMenuTrigger.open {
  background-color: var(--slate-darken-20);
}

.ellipsisMenuTrigger.open .ellipsisIcon {
  stroke: var(--white);
}
