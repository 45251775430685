@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.eligibilityContainer,
.eligibilityStatusInnerContainer {
  display: flex;
}

.eligibilityStatusInnerContainer {
  align-items: center;
  flex-wrap: wrap;
}

.eligibilityContainer {
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.eligibilityContainer > div:first-child {
  flex-shrink: 0;
}

.eligibilityCheckStatus {
  max-width: 150px;
}

.eligibilityCheckMessageContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.eligibilityCheckMessage {
  font-size: 11px;
  line-height: 15px;
  color: var(--slate-85);
}

.eligibilityDetailsContainer {
  display: flex;
  padding-top: 12px;
}

.eligibilityDetailsContainer {
  flex-direction: column;
}

.eligibilityDetailsContainer:first-child {
  padding-top: 0;
}

.eligibilityDetailsContainer {
  padding-bottom: 12px;
  margin: 0;
  border-bottom: solid 1px;
  border-color: var(--light-steel);
}

.eligibilityDetailsContainer > div:first-child {
  line-height: 16px;
  font-size: 12px;
  color: var(--slate-85);
}

.eligibilityDetails {
  display: flex;
  justify-content: space-between;
}

.eligibilityDetails > .toolTipButton {
  margin-left: 14px;
}

.eligibilityMessageContainer {
  max-width: 300px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  color: var(--slate-85);
  display: flex;
}

.eligibilityStatusLine {
  display: flex;
  align-items: center;
}

.eligibilityStatusTitle {
  margin-right: 4px;
  color: var(--grey-95);
}

.eligibilityStatusIcon {
  display: flex;
  padding: 4px 6px;
}

.eligibilityStatusIcon:hover {
  background-color: var(--off-light-grey);
  border-radius: 4px;
}

.eligibilityStatusIcon:hover > button > svg > path {
  fill: var(--slate);
}

.eligibilityTooltip > .eligibilityStatusOuterContainer {
  font-size: 9px;
  line-height: 12px;
}

.eligibilityTooltipLine {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 12px;
}

.eligibilityTooltipNote {
  font-size: 9px;
  line-height: 12.26px;
}

.eligibilityLastChecked {
  line-height: normal;
  font-size: 10px;
  color: var(--grey-85);
  padding-top: 2px;
  margin-left: 10px;
}

.eligibilityStatusOuterContainer {
  width: auto;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: var(--grey-85);
  background: var(--white);
  border-radius: 16px;
  padding: 4px 9px;
  display: inline-block;
}

.EligibleColor,
.IneligibleColor,
.UnverifiedColor {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin: 0.75px 4px 0.75px 0;
}

.EligibleColor {
  background-color: var(--food-dye-green);
}

.IneligibleColor {
  background-color: var(--pink-red);
}

.UnverifiedColor {
  background-color: var(--aspen-yellow);
}
