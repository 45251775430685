.oldCardWrapper {
  position: relative;
  width: 100%;
  max-width: 504px;
}

.oldCard {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 12px;
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  font-size: 14px;
  text-align: left;
  color: var(--slate-85);
  background-color: var(--white);
  cursor: pointer;
}

.oldCreated {
  width: calc(100% - 22px);
}

.oldFilename {
  width: calc(100% - 22px);
  margin-bottom: 4px;
  font-weight: 600;
  color: var(--slate-darken-20);
}

.cardWrapper {
  position: relative;
  width: 100%;
}

.card {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 12px;
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  font-size: 14px;
  text-align: left;
  color: var(--slate-85);
  background-color: var(--white);
}

.docIcon {
  margin-right: 12px;
  color: var(--slate);
}

.info {
  flex-grow: 1;
}

.filename {
  display: inline-block;
  max-width: calc(100% - 22px);
  padding: 0 0 4px 0;
  border: none;
  font-weight: 600;
  text-align: left;
  overflow-wrap: anywhere;
  color: var(--eden-darken-10);
  background-color: transparent;
  cursor: pointer;
}

.filename:focus-visible {
  outline-color: var(--eden-darken-10);
}

.created {
  width: calc(100% - 22px);
}

.notesUpdates {
  width: calc(100% - 22px);
  margin-top: 4px;
}

.notes {
  line-height: 1.25;
  overflow-wrap: anywhere;
}

.notes,
.updated {
  margin-top: 8px;
  margin-bottom: 0;
}

.updated {
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 12px;
}

.error {
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
  color: var(--razz-darken-20);
}

.errorIcon {
  margin-left: 4px;
}

.errorIcon path {
  fill: var(--razz-darken-20);
}

.cardMenu {
  position: absolute;
  top: 8px;
  right: 7px;
}
