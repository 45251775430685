.outerWrapper {
  position: relative;
}

.dropdown {
  background: var(--white);
  box-shadow: 0px 2px 8px rgba(58, 84, 91, 0.15), 0px 0.5px 1px rgba(45, 65, 71, 0.15);
  border-radius: 4px;
  position: absolute;
  display: none;
  z-index: 2001;
}

.isOpen {
  display: block;
}

.closeArea {
  position: fixed;
  top: 0;
  cursor: pointer;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
}

:global(.blurring.dimmable > :not(.dimmer)) {
  filter: none;
}
