.dropdown {
  width: 300px;
  margin-top: 6px;
  padding-bottom: 12px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 100%;
  right: 0;
  cursor: default;
}

.titleContainer {
  margin-left: 18px;
  margin-bottom: 6px;
  padding-top: 8px;
}

.titleContainer:hover {
  cursor: pointer;
}

.titleArrow {
  display: inline-block;
  margin-left: 4px;
  transform: translateY(-1px);
}

.titleArrowClosed {
  transform: rotate(270deg) translateY(-2px) translateX(-1px);
}

.title {
  display: inline-block;
}

.licensingOverview {
  border-radius: 100px;
  display: flex;
  width: fit-content;
  margin: 16px 12px 0 16px;
  padding: 5px 12px 5px 6px;
}

.licensingOverviewIcon {
  margin-right: 6px;
}

.searchContainer {
  margin: 24px 18px -12px 18px;
}

.search > div {
  width: 254px;
}
