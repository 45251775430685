.label {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
  padding: 24px 0 8px 16px;
}

.label.careteamLabel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 6px;
}

.label.careteamLabel :first-child {
  padding-bottom: 2px;
}
