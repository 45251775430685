@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.healthQuestionnaires {
  margin-top: 4px;
}

.healthQuestionnaires .screenerResultsCard:not(:last-child) {
  margin-bottom: 8px;
}

.screenerResultsCard {
  width: 100%;
  padding: 12px 0;
  background-color: var(--white);
  border: 1px solid var(--slate-15);
  border-radius: 8px;
}

.screenerType {
  color: var(--slate-darken-20);
  font-weight: var(--semibold);
  width: 95px;
  float: left;
}

.screenerType div {
  margin-left: 12px;
}

.screenerInfoCurrent {
  color: var(--slate-darken-20);
}

.screenerInfoPast {
  color: var(--slate-85);
  font-style: italic;
}

.screenerResultsContent {
  display: flex;
  flex-direction: column;
}

.screenerResultsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.screenerResultsExpanded {
  margin-top: 12px;
}

.screenerResultsExpanded .screenerResultsRow:not(:first-child) {
  margin-top: 6px;
}

.screenerDropdown {
  float: right;
  width: 40px;
  height: 20px;
  cursor: pointer;
  text-align: right;
}

.screenerDropdown svg {
  margin-right: 12px;
}

.screenerDropdownHidden {
  composes: screenerDropdown;
  visibility: hidden;
}

.loading {
  display: flex;
  align-items: center;
  padding-left: 12px;
  height: 45px;
}

.loading svg {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.loadingMessage {
  color: var(--slate);
  font-weight: var(--semibold);
  padding-left: 8px;
  display: inline;
}

.errorCard {
  width: 100%;
  height: 45px;
  padding: 12px;
  background-color: var(--razz-20);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.errorMessage {
  padding: 0 8px;
  color: var(--slate);
}

.errorRefresh {
  cursor: pointer;
  height: 20px;
}
