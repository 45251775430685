.container {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 44px;
  border-radius: 6px;
  background-color: var(--white);
}

.month {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-size: 8px;
  line-height: 12px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  background: var(--eden-gradient);
}

.month.inactive {
  background: var(--slate-15);
  color: var(--slate);
}

.month.past {
  background: var(--slate-25);
  color: var(--slate);
}

.dayOfMonth {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--slate);
  height: 100%;
  padding-top: 3px;
}

.dayOfMonth.active {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom: 1px solid var(--slate-15);
  border-right: 1px solid var(--slate-15);
  border-left: 1px solid var(--slate-15);
}

.dayOfMonth.inactive {
  background: var(--slate-05);
}

.dayOfMonth.past {
  background: var(--slate-15);
}
