.base {
  display: block;
}

.label {
  display: block;
  font-size: 13px;
  line-height: 21px;
  color: var(--slate);
}

.header1 {
  font-size: 36px;
  line-height: 49px;
  color: var(--secondary);
}

.header2 {
  font-size: 24px;
  line-height: 33px;
  color: var(--secondary);
}

.header3 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: var(--secondary);
}

.body,
.body1,
.body2 {
  color: var(--slate);
}

.bodySemiBold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.body1 {
  font-size: 14px;
  line-height: 23px;
}

.body2 {
  font-size: 13px;
  line-height: 21px;
}

.body.small {
  font-size: 14px;
  line-height: 19px;
}

.disclaimer {
  font-size: 10px;
  line-height: 14px;
  color: var(--off-slate);
}

.errorMessage {
  font-size: 10px;
  line-height: 14px;
  color: var(--clifford-red);
}

.listHeaderSmall {
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary);
}

.listHeaderMedium {
  /* design system to do: add necessary font files and increase weight to 600 */
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--secondary);
}

.listSubhead {
  font-size: 10px;
  line-height: 14px;
  color: var(--off-slate);
}

.listBody {
  font-size: 14px;
  line-height: 150%;
  color: var(--slate-85);
}

.listBodySmall {
  font-size: 12px;
  line-height: 16px;
}

.eyebrow1 {
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--slate);
}

.header {
  font-size: 24px;
  line-height: 135%;
  color: var(--secondary);
}

.description {
  font-size: 14px;
  line-height: 23px;
  color: var(--slate);
}

.caption {
  font-size: 12px;
  line-height: 120%;
  color: var(--green-5);
}
