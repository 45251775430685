@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.columnContainer {
  flex-direction: column;
  width: 100%;
}

.details,
.copayDetails {
  display: flex;
  flex-wrap: wrap;
}

.details {
  margin-bottom: 8px;
}

.panel {
  flex-grow: 1;
  min-width: 200px;
}

.panel:first-child {
  width: 50%;
  flex-grow: 0;
  min-width: 175px;
  max-width: 200px;
}

.detailsContainer,
.copayDetailsContainer {
  display: flex;
  padding-top: 12px;
}

.detailsContainer:first-child {
  padding-top: 0;
}

.detailsContainer:not(:last-child) {
  padding-bottom: 12px;
  margin: 0;
  border-bottom: solid 1px;
  border-color: var(--light-steel);
}

.detailsContainer > div,
.copayDetailsContainer > div {
  width: 50%;
  justify-content: flex-end;
}

.detailsContainer > div:first-child,
.copayDetailsContainer > div:first-child {
  line-height: 16px;
  font-size: 12px;
  color: var(--grey-95);
}

.detailsContainer > div:last-child,
.copayDetails > div:last-child,
.unknownCopay {
  text-align: right;
  line-height: 16px;
  font-size: 12px;
  text-align: right;
  color: var(--slate);
  font-weight: 600;
  text-transform: capitalize;
}

.ircName {
  line-height: 21px;
  font-size: 18px;
  letter-spacing: 0.125px;
  color: var(--grey-95);
  margin-top: 8px;
  margin-bottom: 24px;
}

.copayDetails > div:first-child {
  line-height: 21px;
  font-size: 10px;
  text-align: right;
  color: var(--grey-80);
  padding-right: 4px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
}

.button {
  font-family: 'Open Sans', sans-serif;
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  user-select: none;

  color: var(--grey-84);
  padding: 5px 12px;
  margin-bottom: 10px;
  border: 1px solid var(--grey-15);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;

  transition: color 0.15s, background-color 0.15s, border-color 0.15s;
}

.button:not(.buttonInactive) {
  cursor: pointer;
}

.button > svg {
  margin-right: 8px;
}

.button > svg > path {
  transition: fill 0.15s;
}

.button:hover:not(:active):not(.buttonInactive) {
  color: var(--eden-darken-10);
}

.button:hover:not(:active):not(.buttonInactive) > svg > path {
  fill: var(--eden-darken-10);
}

.buttonInactive,
.button:active {
  background-color: var(--slate-15);
  border-color: var(--slate-15);
}

.header {
  display: flex;
}

/* TODO: Remove when deprecating old insurance card view */
.noPhotoOld {
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 10px;

  color: var(--grey-80);
}

.noPhotoContainer {
  display: flex;
  justify-content: flex-start;
}

.noPhoto {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  user-select: none;

  color: var(--razz-darken-20);
  padding: 5px 12px;
  border: 1px solid var(--razz-20);
  background-color: var(--razz-20);
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.noPhoto > svg {
  margin-right: 8px;
}

.photoNoDetailsContainer {
  display: flex;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-85);
}

.photoNoDetailsHeader {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
  margin-bottom: 4px;
}

.photoNoDetailsText {
  flex-grow: 1;
}

.smallMessageContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-right: 10px;
}

.smallMessage {
  font-size: 11px;
  line-height: 15px;
  color: var(--slate);
  max-width: 200px;
}

.smallLoadingIcon,
.insuranceSmallSuccessIcon,
.insuranceSmallErrorIcon,
.insuranceSmallWarningIcon {
  margin-right: 8px;
}

.smallLoadingIcon > svg {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.insuranceSmallSuccessIcon,
.insuranceSmallErrorIcon,
.insuranceSmallWarningIcon {
  margin-top: 2px;
  border-radius: 9999px;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.insuranceSmallSuccessIcon {
  background-color: var(--apple-40);
}

.insuranceSmallErrorIcon {
  background-color: var(--razz-20);
}

.insuranceSmallWarningIcon {
  background-color: var(--lemon-40);
}

/* TODO: remove when removing automated insurance flag */
.viewPhotoButton {
  font-family: 'Open Sans', sans-serif;
  line-height: normal;
  font-size: 10px;

  color: var(--grey-84);
  padding: 5px 12px;
  border: 1px solid var(--grey-15);
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-block;
  cursor: pointer;
}
