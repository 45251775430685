.tag {
  display: inline-block;
  background-color: var(--slate-05);
  color: var(--slate-85);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 1px;
  border-radius: 32px;
  padding: 8px;
  margin-right: 8px;
}
