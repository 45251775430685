.container {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 24px;
}

.label {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--slate-darken-20);
  padding: 24px 4px 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ticklerCard {
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  margin-top: 12px;
  padding: 12px;
}

.ticklerCardHeader {
  display: flex;
  justify-content: space-between;
}

.ticklerCardDueLine {
  font-weight: 600;
}

.ticklerDueLabel {
  border-radius: 4px;
  font-weight: 700;
  padding: 4px 6px 4px 6px;
  font-size: 10px;
  margin-right: 8px;
  vertical-align: top;
  letter-spacing: 1px;
}

.dueTodayLabel {
  color: var(--slate-darken-20);
  background-color: var(--lemon-20);
}

.overdueLabel {
  color: var(--razz);
  background-color: var(--razz-10);
}

.ticklerDropdown {
  display: inline-block;
}

.ticklerListTitle {
  margin: 0 4px 0 0;
}

.ticklerListInfoGroup {
  display: flex;
  align-items: center;
}

.partialFailureNotice {
  background-color: var(--lemon-10);
  color: var(--lemon-darken-20);
  border: 1px solid var(--lemon-darken-20);
  border-radius: 2px;
  padding: 9px 0 9px 12px;
  margin: 0 0 8px 0;
}

.ticklersFetchedAtNotice {
  color: var(--slate-85);
  font-size: 12px;
}

.ticklersListRefetchCenter {
  display: flex;
  align-items: center;
  padding: 0 4px 12px;
}

.refetchWrapper {
  height: 16px;
  width: 16px;
  margin: 0 0 0 4px;
  stroke: var(--slate-55);
}

.refetchWrapper:hover {
  cursor: pointer;
  stroke: var(--slate-85);
}

.refetchWrapper.loading:hover {
  cursor: auto;
}

.refetchWrapper.loading .refetchIcon {
  -webkit-animation: spin 1s ease-in-out infinite;
  -moz-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hoverable {
  display: flex;
  padding-right: 53px;
  border-radius: 4px;
}

.hoverable:hover {
  background: var(--slate-05) !important;
}

.hoverableContainer {
  display: block;
}

.copyButton {
  left: unset;
  display: inline-block;
  border: 0;
  top: 0;
  right: 0;
  width: 53px;
  text-align: right;
  background: transparent;
}
