.radioInput {
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  transform: translateY(0.05em);
  border: 0.1em solid var(--slate-15);
  border-radius: 50%;
  font: inherit;
  color: currentColor;
}

.radioInput:checked {
  border-color: var(--eden);
}

.radioInput::before {
  content: '';
  width: 10px;
  height: 10px;
  transform: scale(0);
  border-radius: 50%;
  background: var(--eden);
  transition: 120ms transform ease-in-out;
}

.radioInput:checked::before {
  transform: scale(1);
}

.radioLabel {
  margin-bottom: 8px;
  color: var(--slate-darken-20);
  cursor: pointer;
}

.radioLabelText {
  display: inline-block;
  line-height: 20px;
}
