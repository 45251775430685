.googleLoginText {
  align-items: center;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  letter-spacing: 0.21px;
  line-height: 48px;
  margin-left: 6px;
  margin-right: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.googleLoginIcon {
  width: 18px;
  height: 18px;
}

.googleLoginIconWrapper {
  background-color: white;
  float: left;
  padding: 15px;
}

.googleLoginButtonBorder {
  border: 1px solid transparent;
  height: 100%;
  width: 100%;
}

.googleLoginButton {
  align-items: center;
  background-color: var(--google-blue);
  border-radius: 1px;
  border: none;
  color: var(--white);
  cursor: pointer;
  display: flex;
  display: inline-block;
  height: 50px;
  padding: 0px;
  text-align: center;
  transition: all 0.3s;
  width: 240px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.googleLoginButton:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.googleLoginButton:active {
  filter: brightness(80%);
  opacity: 1;
}
