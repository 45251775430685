.switch {
  display: inline-flex;
  position: relative;
  width: 2.75rem;
  height: 1.5rem;
  cursor: pointer;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background: var(--slate-15);
}

.slider:before {
  position: absolute;
  content: "";
  background: var(--white);
  width: 1.125rem;
  height: 1.125rem;
  top: .1875rem;
  left: .25rem;
  border-radius: 50%;
}

.checked {
  background: var(--eden);
}

.checked:before {
  transform: translateX(1.125rem);
}
