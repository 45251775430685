.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  color: var(--slate);
  padding: 8px;
}

.inline {
  height: 38px;
  border-radius: 6px;
}

.center {
  justify-content: center;
}

.absolute {
  position: absolute;
  z-index: 9292;
}

.alert a {
  color: var(--green-5);
}

.icon {
  margin-right: 8px;
  margin-top: 5px;
}

.error {
  background: var(--razz-20);
}

.info {
  background: var(--lemon-20);
}
