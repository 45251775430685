.icon {
  color: var(--slate-darken-20);
  stroke: var(--slate-darken-20);
  fill: var(--slate-darken-20);
}

.icon.ellipsis {
  width: 18px;
  height: 18px;
}

.overflowMenuTrigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 4px;
}

.overflowMenuTrigger:hover {
  cursor: pointer;
  background-color: var(--slate-15);
}

.overflowMenuTrigger.open {
  background-color: var(--slate-darken-20);
}

.overflowMenuTrigger.open .icon.ellipsis {
  stroke: var(--white);
}

.item {
  min-width: 140px;
}
