.customTimeFrameContainer {
  max-width: 500px;
  padding-top: 16px;
}

.customTimeFrameContainer .convoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.customTimeFrameContainer .bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
}

.convoContainer .convoLabel {
  color: var(--slate);
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.customTimeFrameContainer .svgCircleSpinner {
  -webkit-animation: svg-animation 1s steps(8, end) infinite;
  -moz-animation: svg-animation 1s steps(8, end) infinite;
  animation: svg-animation 1s steps(8, end) infinite;
}
