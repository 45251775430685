.selectedOption {
  padding: 6px;
  display: flex;
  align-items: center;
  color: var(--slate);
  font-size: 12px;
  border-radius: 6px;
}

.selectedOption:hover {
  cursor: pointer;
  background-color: var(--slate-15);
}

.selectedOption.open {
  color: var(--white);
  background-color: var(--slate-darken-20);
}

.selectedOption.open .icon {
  fill: var(--white);
}

.icon {
  margin-left: 4px;
}
