@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  margin-bottom: 8px;
  padding-left: 22px;
  border: none;
}

.loading {
  display: flex;
  background-color: var(--slate-15);
  height: 44px;
  margin-right: 14px;
  border-radius: 6px;
  align-items: center;
  padding-left: 12px;
}

.loading svg {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.loadingStatusContainer {
  margin-top: 32px;
}

.loadingStatusMessage {
  padding-left: 8px;
}

.error {
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 9px;
  margin-right: 14px;
  background-color: var(--razz-20);
  border-radius: 6px;
  cursor: pointer;
}

.errorReload {
  padding-top: 2px;
  margin-left: 8px;
  height: 20px;
}
