.app {
  height: 100%;
  width: 100%;
}

:global .warningToast,
:global .warningToast .Toastify__close-button {
  color: var(--secondary-900);
  background-color: var(--pale-sand);
}

:global .warningToast .Toastify__progress-bar {
  background-color: var(--unhappy-yellow);
}

:global .Toastify__toast--success {
  background-color: var(--apple-40);
}

:global .Toastify__toast--success :global .Toastify__toast-body,
:global .Toastify__toast--success :global .Toastify__close-button {
  color: var(--slate-darken-20);
}

/* Fixes the scrollbar styles for the Scroll Modal component from @edenhealth/web */
/* TODO: Remove below style after semantic CSS is no longer in use */
div[role='dialog'][data-ismodal='true'] div[class*='scrollContainer']::-webkit-scrollbar-thumb {
  background: rgba(217, 217, 217, 1);
}
