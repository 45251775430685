.container {
  display: flex;
  flex-direction: column;
  padding-right: 5px;
}

.innerContainer {
  border-radius: 100px;
  color: var(--slate-darken-20);
  background-color: var(--slate-15);
  padding: 4px 9px 4px 9px;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  display: flex;
}
