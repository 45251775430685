.hamburgerContainer {
  display: none;
}

@media (max-width: 1100px) {
  .hamburgerContainer {
    background-color: var(--grey-05);
    display: flex;
    border: 0;
    outline: none;
    left: 5px;
    top: 10px;
    padding: 4px 6px;
    z-index: 10;
    vertical-align: middle;
    border-radius: 5px;
    position: fixed;
    cursor: pointer;
  }

  .hamburgerContainerOpen {
    composes: hamburgerContainer;
    display: flex;
    left: calc(18rem + 10px);
  }

  .hamburgerContainer:hover {
    background-color: var(--grey-10);
  }
}

@media (max-width: 767px) {
  .chatToggle {
    background-color: var(--grey-05);
    display: flex;
    border: 0;
    outline: none;
    left: 5px;
    top: 10px;
    padding: 4px 6px;
    z-index: 10;
    vertical-align: middle;
    border-radius: 5px;
    position: fixed;
    cursor: pointer;
    top: 50px;
  }
}

@media (min-width: 767px) {
  .chatToggle {
    display: none;
  }
}
