.menu {
  float: right;
  height: 30px;
  cursor: pointer;
}

.menu:hover {
  background: var(--off-light-grey);
  border-radius: 4px;
  margin-bottom: -4px;
}

.profilePicture {
  float: right;
  padding-top: 3px;
  padding-right: 6px;
}

.logo {
  display: inline-block;
  cursor: pointer;
}

.nav {
  padding: 16px 12px;
}

@media (max-width: 767px) {
  .nav {
    position: relative;
    z-index: 1002;
    background-color: rgb(255, 255, 255);
  }
}

@media (max-width: 1100px) {
  .menu {
    display: none;
  }
}

.localProdModeWarning {
  padding-bottom: 20px;
  font-weight: 800;
}

.menu:hover .hideOnHover {
  display: none;
}
.menu:hover .showOnHover {
  display: inline-block;
}

.showOnHover {
  display: none;
}
