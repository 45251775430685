.formHeaderText {
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: var(--secondary);
}

.formHeader {
  display: flex;
  align-content: center;
  margin-bottom: 38px;
}

.icon {
  padding: 4.5px 18px 4.5px 0px;
  height: 33px;
}

.labelText {
  margin-bottom: 4px;
}

.fieldContainer {
  margin-bottom: 24px;
}

.field {
  width: 450px;
  height: 40px;
}

.field::placeholder {
  color: var(--grey-4);
}

.error {
  color: var(--razz-darken-20);
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
}

.submitButton {
  height: 38px;
  width: 108px;
}

.submitButtonContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 6px;
}
