.cardWrapper {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 4px;
  padding: 0;
}

.card {
  display: flex;
  min-width: 350px;
  padding: 12px 14px;
  border-radius: 6px;
}

.buttonCard {
  flex-direction: column;
  border: 1px solid var(--slate-15);
  border-radius: 6px;
  background-color: var(--white);
  cursor: pointer;
}

.file {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  word-break: break-all;
  text-align: left;
  color: var(--slate);
}

.filename {
  padding-top: 1px;
}

.documentIcon {
  margin-right: 14px;
}

.for {
  margin-left: 29px;
  font-weight: 400;
  font-size: 12px;
  color: var(--slate);
}

.notes {
  margin-top: 14px;
  margin-left: 29px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.35;
  color: var(--slate);
  text-align: left;
}

.withFilename {
  word-break: break-all;
}
