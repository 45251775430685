.modalContent {
  height: auto;
  max-height: fit-content;
  max-width: fit-content;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  outline: none;
  padding: 40px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scrollable {
  padding: 0px;
  min-width: 480px;
  height: 620px;
  overflow-y: auto;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.xIcon {
  position: absolute;
  top: 32px;
  right: 44px;
  cursor: pointer;
}

.xIconScrollable {
  right: 36px;
}
