.greyPill,
.redPill,
.bluePill,
.greenPill {
  font-size: 11px;
  line-height: 15px;
  height: 16px;
  max-height: 16px;
  min-height: 16px;
  mix-blend-mode: normal;
  border-radius: 100px;
  margin: 2px 0 0 4px;
  padding: 0 4px;
  display: inline-block;
}

.greyPill {
  background: var(--off-light-grey);
}

.redPill {
  background: var(--pale-pink);
}

.bluePill {
  background: var(--off-light-grey);
  color: var(--grape-darken-20);
}

.greenPill {
  background: var(--apple-40);
  color: var(--eden-darken-20);
}

.greyPill.darker {
  background: var(--slate-25);
}

.profilePictureGroup {
  position: relative;
}

.profilePicture {
  position: absolute;
  border: 0;
  border-radius: 24px;
  display: inline-block;
}

.profilePicture.normal {
  height: 24px;
  max-height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  overflow: hidden;
}

.profilePicture.normal .profilePictureImage {
  height: 24px;
  width: 24px;
}

.profilePicture.large {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  max-height: 28px;
  min-height: 28px;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  overflow: hidden;
}

.profilePicture.large .profilePictureImage {
  border-radius: 28px;
  border: 1px solid var(--off-light-grey);
  height: 28px;
  width: 28px;
}

.profilePicture.small {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  max-height: 20px;
  min-height: 20px;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  overflow: hidden;
}

.profilePicture.small .profilePictureImage {
  border-radius: 20px;
  border: 1px solid var(--off-light-grey);
  height: 20px;
  width: 20px;
}

.emptyProfilePicture {
  composes: profilePicture;
  right: 0;
}

.emptyProfilePictureImage {
  composes: profilePictureImage;
  font-size: 9px;
  font-weight: bold;
  background-color: var(--off-slate);
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.profilePicture.jumbo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  overflow: hidden;
}

.profilePicture.jumbo .profilePictureImage {
  border-radius: 40px;
  border: 1px solid var(--off-light-grey);
  height: 40px;
  width: 40px;
}

.expandablePillOuter {
  border-radius: 100px;
  height: 24px;
  display: flex;
}

.expandablePillIcon {
  margin: 6px;
}

.expandablePillText {
  margin: 4px 8px 0 -2px;
  line-height: 16px;
}

.expandablePillOuter .expandablePillText {
  display: none;
}

.expandablePillOuter:focus {
  outline: none;
}

.expandablePillOuter:hover .expandablePillText,
.expandablePillOuter:focus .expandablePillText {
  display: inline-block;
}
