.modal {
  top: 50%;
  max-height: 80%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.main {
  width: 522px;
  flex: 1;
  overflow-y: auto;
  padding: 24px 32px;
}

.header {
  color: var(--slate);
  border-bottom: 1px solid var(--slate-15);
  padding: 24px 32px;
}

.footer {
  border-top: 1px solid var(--slate-15);
  padding: 24px 32px;
  display: flex;
  justify-content: flex-end;
}
