.button {
  font-weight: normal;
}

.button:focus {
  color: var(--white);
}

.openStatus {
  color: var(--razz);
}

.openStatus:hover {
  color: var(--razz);
}

.modalClosed {
  background-color: rgba(0, 0, 0, 0);
}

.modalClosed {
  background-color: rgba(0, 0, 0, 0);
}

.modalOpen {
  color: var(--white);
  background-color: var(--slate);
}

.modalOpen:hover {
  color: var(--white);
}

.dropdownStyle {
  bottom: 38px;
  width: 120px;
  left: -20px;
}

.row {
  padding-right: 30px;
  font-weight: normal;
}

.chevron {
  margin: 0px 0px -3px 7.75px;
}
