.container {
  width: 450px;
  margin-top: 30px;
}

.bellIcon {
  vertical-align: -4px;
  margin-right: 12px;
}

.wide {
  width: 100%;
}

.dayPicker {
  margin-right: 8px;
}

.daysFromNow {
  width: 50px;
  margin-left: 8px;
  margin-right: 8px;
}

.note {
  height: 100px;
  resize: none;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  width: 151px;
  height: 44px;
}

.cancelBtn,
.cancelBtn:active {
  background: var(--slate-05);
  color: var(--slate-darken-20);
  margin-right: 16px;
}

.charCounter {
  color: var(--slate-55);
  font-size: 13px;
}
