.generateAVSContainer {
  padding: 8px 0px 12px 20px;
}

.generateAVSContainer .header {
  font-weight: var(--semibold);
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-darken-20);
  padding-left: 2px;
}

.generateAVSContainer .timeFrameHeader {
  font-size: 12px;
  line-height: 16px;
  color: var(--slate);
  padding: 8px 0px 3px 3px;
}

.generateAVSContainer .buttonDisabled {
  pointer-events: none;
}

.generateAVSContainer .timeFrameContainer {
  display: flex;
  flex-direction: row;
}

.timeFrameContainer .dropdown {
  width: 160px;
}

.timeFrameContainer li {
  font-size: 12px;
  line-height: 16px;
  color: var(--slate);
  padding: 4px 8px;
}

.timeFrameContainer .timeFrameButton {
  border: 0.5px solid var(--off-light-grey);
  border-radius: 4px;
  padding: 11px 8px;
  margin-right: 11px;
}

.timeFrameButton > div {
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeFrameContainer .generateButton {
  padding: 9px 13px;
}

.timeFrameOption > span {
  display: flex;
  align-items: center;
  gap: 8px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.generateAVSContainer .svgCircleSpinner {
  -webkit-animation: svg-animation 1s steps(8, end) infinite;
  -moz-animation: svg-animation 1s steps(8, end) infinite;
  animation: svg-animation 1s steps(8, end) infinite;
}
