.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--off-white-blue);
  height: 100%;
  box-shadow: inherit;
}

.msgContainer {
  margin: 0 !important;
  overflow-y: scroll; /* has to be scroll, not auto, to be smooth on iOS */
  -webkit-overflow-scrolling: touch;
  padding: 0 !important;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.msgContainer:hover {
  overflow-y: overlay;
}

@media (max-width: 767px) {
  .msgContainer,
  .msgContainer:hover {
    overflow-y: scroll;
  }
}

.msgHeader {
  margin: 10px;
}

.msgBody {
  margin: 0 10px !important;
  max-width: none !important;
}

.commentHeaderContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.commentMeta {
  align-self: center;
}

.commentText {
  white-space: pre-wrap;
}
