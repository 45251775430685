@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

::-webkit-calendar-picker-indicator {
  background-image: url(../../assets/calendarIcon.svg);
}

.selectNew {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

.selectNew.error {
  border: 1px solid var(--razz);
}

.field,
.select,
.selectNew,
.selectNew > select:active,
.selectNew > select:focus,
.fieldContainer > input,
.fieldContainer > input:active,
.fieldContainer > input:focus {
  --padding-width: 12px;
  --icon-width: 16px;
  --border-width: 1px;
  margin: 0;
  border: var(--border-width) solid var(--slate-15);
  border-radius: 4px;
  color: var(--slate-darken-20);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
  line-height: 20px;
  height: 40px;
  outline: none;
  padding: 10px 12px;
}

.select,
.selectNew {
  padding: 9px calc(var(--icon-width) + var(--padding-width)) 9px var(--padding-width);
}

.field:focus,
.field:active,
.selectNew:focus,
.selectNew:active {
  border: 1px solid var(--apple);
}

.fieldContainer > input,
.select,
.selectNew,
.selectNew:focus,
.select:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../assets/chevronDown.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.select.arrowless,
.select.arrowless:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  padding: 8px 16px;
  width: 100%;
}

.select:disabled,
.selectNew:disabled,
.field:disabled {
  pointer-events: none;
  color: var(--slate-darken-20);
  background-color: var(--slate-05);
  border-color: var(--slate-05);
  opacity: 1;
}

.dateInput,
.timeInput {
  cursor: pointer;
  width: 160px;
}

.dateInput:disabled,
.timeInput:disabled {
  cursor: default;
  background-color: var(--slate-05);
  color: var(--slate-35);
}

.option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fieldContainer.fieldContainerOpen > input,
.fieldContainer.fieldContainerOpen > input:hover {
  background-color: var(--slate-15);
}

.field.error {
  border: 1px solid var(--razz);
  color: var(--razz-darken-20);
}

.button,
.buttonLoading:active,
.buttonLoading:focus {
  font-family: 'Open Sans', sans-serif !important;
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: var(--semibold);
  font-size: 16px;
  line-height: 135%;
  color: white;
}

.button:hover {
  cursor: pointer;
}

.button:disabled {
  background: var(--slate-05);
  color: var(--slate-55);
  cursor: default;
  pointer-events: none;
}

.buttonLoading {
  background: var(--eden);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 132px;
  max-width: 400px;
}

.primaryButton {
  background: var(--eden-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
}

.primaryButton:focus,
.primaryButton:active {
  background-color: var(--eden-darken-10);
  background-image: none;
}

.secondaryButton {
  background: var(--slate-05);
  color: var(--slate-darken-20);
}

.buttonLoading .inner {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.dayPickerOverlayWrapper {
  display: block !important;
  justify-content: flex-end;
}

.dayPickerOverlay {
  position: fixed;
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  color: var(--slate-darken-20);
  z-index: 1001;
}

.dayPickerOverlay:focus {
  outline: none;
}

.dayPicker:focus {
  outline: none;
}

.monthSelector {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid var(--slate-15);
  background: white;
}

.monthSelector:focus {
  outline: none;
}

.monthSelector:disabled {
  background: var(--slate-05);
  border: none;
  cursor: default;
}

.dayPickerNavigation {
  position: absolute;
  right: 24px;
  top: 24px;
}

.dayPickerOverlayWrapper :global(.DayPicker-wrapper) {
  padding: 0;
}

.dayPickerOverlayWrapper :global(.DayPicker-Month) {
  margin: 24px;
}

.dayPickerOverlayWrapper :global(.DayPicker-Day) {
  width: 24px;
  height: 24px;
  font-size: 16px;
}

.dayPickerOverlayWrapper :global(.DayPicker-Caption) {
  color: var(--eden-darken-10);
  margin-bottom: 24px;
  font-size: 16px;
}

.dayPickerOverlayWrapper :global(.DayPicker-Day--today) {
  color: var(--eden);
}

.dayPickerOverlayWrapper :global(.DayPicker-NavButton--prev) {
  background-image: url(../../assets/leftArrow.png) !important;
}

.dayPickerOverlayWrapper :global(.DayPicker-NavButton--next) {
  background-image: url(../../assets/rightArrow.png) !important;
}

.dayPickerOverlayWrapper :global(.DayPicker-Day--selected) {
  background-color: var(--eden) !important;
  font-weight: normal !important;
}

.dayPickerOverlayWrapper
  :global(
    .DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(
        .DayPicker-Day--selected
      ):hover
  ) {
  background-color: var(--apple-20) !important;
  color: var(--slate-darken-20) !important;
}

.selectCover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.selectModal {
  background: white;
  margin-top: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px;
  max-height: 600px;
  overflow-y: auto;
}

.selectValue {
  padding: 8px;
  font-size: 14px;
  color: var(--slate-85);
}

.selectValue:hover {
  background: var(--slate-05);
  border-radius: 4px;
  color: var(--slate-darken-20);
  cursor: pointer;
}

.modalOuter {
  position: fixed;
  height: 0;
  z-index: 2001;
}

.label {
  margin-bottom: 16px;
  display: block;
}

.labelText {
  margin-bottom: 8px;
  font-weight: var(--semibold);
  font-size: 12px;
  line-height: 16px;
  color: var(--slate-darken-20);
  text-align: left;
}

.labelText.required::after {
  content: ' *';
  color: var(--razz-darken-20);
}

.linkWithIcon {
  display: flex;
  align-items: center;
}

.linkWithIcon:hover {
  cursor: pointer;
}

.listItemInner {
  font-size: 16px;
  line-height: 24px;
  color: var(--slate-darken-20);
  padding-left: 18px;
}

.listItemOuter {
  display: flex;
  padding-bottom: 13px;
  border-width: 0 0 1px 0;
  border-color: var(--slate-15);
  border-style: solid;
  margin-bottom: 13px;
}

.listItemOuter:last-child {
  border: none;
}

.listItemOuter:hover {
  cursor: pointer;
}

.errorText {
  font-size: 12px;
  line-height: 150%;
  color: var(--razz-darken-20);
  margin-top: 4px;
}

.textArea {
  width: 456px;
  height: 136px;
  margin-bottom: 8px;
  border: 1px solid var(--slate-15);
  border-radius: 4px;
  outline: none;
}

.textArea:focus,
.textArea:active {
  border: 1px solid var(--apple);
}

.textArea.error {
  border: 1px solid var(--razz);
  color: var(--razz-darken-20);
}

.placeholder {
  color: var(--slate-45);
}
