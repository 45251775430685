.oldFiles {
  margin: 8px 18px 8px 0;
}

.category {
  margin-top: 24px;
}

.category:first-child {
  margin-top: 0;
}

.categoryName {
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: var(--slate-darken-20);
  letter-spacing: 1.25px;
}
