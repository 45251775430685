.trashIcon {
  vertical-align: -2px;
  margin-right: 12px;
}

.tickler {
  border: 1px solid var(--slate-15);
  border-radius: 8px;
  padding: 12px;
  margin-top: 24px;
  width: 455px;
}

.dueLine {
  font-weight: 600;
  margin-bottom: 8px;
}

.bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
}

.deleteBtn {
  width: 151px;
  height: 44px;
}

.cancelDeleteBtn,
.cancelDeleteBtn:active {
  background: var(--slate-05);
  color: var(--slate-darken-20);
  margin-right: 16px;
}

.confirmDeleteBtn,
.confirmDeleteBtn:active {
  background: var(--razz-gradient);
}
