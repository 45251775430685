.patientNote {
  margin-top: 12px;
  margin-bottom: 8px;
}

.section {
}

.kicker {
  font-size: 8px;
  font-weight: 700;
  color: var(--slate-darken-20);
  margin-bottom: 4px;
}

.comment {
  font-size: 14;
  font-weight: 400;
  color: var(--slate-85);
}
