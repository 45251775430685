.container {
  margin: 0 0 0 8px;
}

.innerButton {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noAlert {
  background-color: var(--slate-15);
}

.alert {
  background-color: var(--razz-20);
}

.popup {
  position: absolute;
  cursor: auto;
  margin: 4px 0 0;
  padding: 16px;
  min-width: 212px;
  max-width: 300px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  font-size: 10px;
  line-height: 12px;
  z-index: 2000;
}

.title {
  text-transform: uppercase;
  font-weight: var(--semibold);
  margin-bottom: 6px;
  margin-left: 8px;
}

.body {
  background-color: var(--slate-05);
  color: var(--slate-85);
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
}
