.hoverableContainer {
  display: inline-block;
  margin-top: 2.5px;
  margin-bottom: 1px;
}

.hoverable {
  display: flex;
  position: relative;
  padding: 0 6px;
  margin-top: -4px;
}

.copyButton {
  z-index: 5;
  position: absolute;
  left: 100%;
  display: flex;
  align-items: center;
  font-size: 9px;
  padding: 0 6px;
  height: 100%;
  line-height: normal;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: var(--off-light-grey);
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: rgba(58, 84, 91, 0.15);
}

.hoverable > .hoverableCopy {
  visibility: hidden;
}

.copiedText {
  visibility: hidden;
  width: 53px;
  color: var(--slime-green);
}

@media (min-width: 768px) {
  .hoverable:hover {
    background: var(--off-light-grey);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-width: 0;
    cursor: pointer;
  }

  .hoverableCopied > .hoverable:hover {
    background: var(--off-light-grey);
    cursor: default;
  }

  .hoverable:hover > .hoverableCopy {
    visibility: visible;
  }

  .hoverableCopied > .hoverable:hover > .hoverableCopy {
    visibility: hidden;
    padding-left: 0;
    margin-left: 0;
  }

  .hoverableCopied > .hoverable:hover > .copiedText {
    visibility: visible;
  }
}

@media (max-width: 768px) {
  .hoverableCopied > .mobileHoverable {
    background: var(--off-light-grey);
    cursor: default;
  }

  .mobileHoverable {
    background: var(--off-light-grey);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-width: 0;
    cursor: pointer;
  }

  .hoverableCopied > .mobileHoverable > .copiedText {
    visibility: visible;
  }
}
