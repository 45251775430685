.button {
  cursor: pointer;
  margin: 0;
  background: var(--eden);
  border-radius: 4px;
  font-size: 14px;
  padding: 9px 18px;
  outline: 0;
  border: none;
}

.tiny,
.tiny.tertiary {
  font-size: 12px;
  padding: 5px;
}

.small {
  font-size: 12px;
  padding: 7px 8px;
}

.wide {
  font-size: 14px;
  padding: 9px 39px;
}

.large {
  font-size: 14px;
  padding: 9px 23px;
  line-height: 23px;
}

.primary {
  background-color: var(--eden);
  color: white;
}

.primary:hover,
.primary.buttonActive {
  background-color: var(--off-jade);
}

.primary.dropdown {
  font-size: 11px;
  line-height: 135%;
  font-weight: 600;
  padding: 5px 16px;
}

.transparent {
  font-weight: normal;
  background-color: transparent;
  color: var(--slate);
}

.link {
  font-weight: normal;
  background-color: transparent;
  color: var(--off-slate);
}

.transparent:hover,
.transparent.buttonActive {
  background-color: rgba(0, 0, 0, 0.05);
}

.link:hover,
.link.buttonActive {
  font-weight: 600;
}

.red {
  background-color: var(--red-4);
  color: white;
}

.grey {
  color: var(--slate);
  background-color: var(--off-white-blue);
}

.grey:hover,
.grey.buttonActive {
  background-color: var(--off-light-grey);
}

.contentAndIcon {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.button.small .iconContainer {
  margin-left: 6px;
}

@media (max-width: 768px) {
  .reactive {
    width: 100%;
  }
}

.tertiary,
.send {
  border-radius: 4px;
  padding: 8px;
}

.tertiary {
  background-color: rgba(0, 0, 0, 0);
  color: var(--slate);
}

.tertiary:hover {
  background: var(--off-light-grey);
  color: var(--slate);
}

.tertiary:active {
  background: var(--slate);
  color: var(--white);
}

.send {
  background: var(--light-sky-blue);
}

.send:hover {
  background: var(--sky-blue);
}

.send:active {
  background: var(--dark-sky-blue);
}

.disabled,
.disabled:hover {
  cursor: default;
  background: var(--slate-05);
  color: var(--slate-35);
}
