.visitSummary {
  display: flex;
}

.icon {
  margin-right: 16px;
}

.body .title {
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--slate-darken-20);
}

.body {
  color: var(--slate);
}
