.dropdown {
  width: 144px;
}

.filterItem {
  font-size: 11px;
  color: var(--slate-darken-20);
  padding: 8px;
}

.count {
  margin-left: auto;
}

.filterItem > span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterButton {
  border: 1px solid var(--slate-15);
  border-radius: 6px;
}

.filterButton > div {
  font-size: 11px;
  line-height: 15px;
  color: var(--slate-darken-20);
  width: 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
