.list1 {
  margin: 0;
  padding: 0;
}

.listItem1,
.listItem3 {
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listItem3 {
  height: 44px;
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 20px;
  padding-bottom: 12px;
}

.listItem3.active {
  background-color: var(--white);
  font-weight: 600;
  cursor: default;
}

.listItem3:hover {
  background-color: var(--slate-15);
}

.listItem3.active:hover {
  background-color: var(--white);
}

.listItem1:first-child {
  margin-top: 6px;
}

.listItem1:last-child {
  margin-bottom: 6px;
}

.listItem1.active {
  background-color: var(--white);
}

.listItem1 {
  font-weight: normal;
  text-transform: initial;
  padding: 6px 12px;
  line-height: 19px;
}

.listItem1 > span {
  width: 100%;
}

.listItem1:hover {
  background-color: var(--off-white-blue);
}

.arrowContainer {
  display: none;
}

.listItem3.active .arrowContainer {
  display: block;
}

.listItem3.disabled {
  color: var(--slate-35);
}

.listItem3.disabled:hover {
  background-color: var(--white);
  cursor: default;
}

.list2 {
  list-style-type: none;
  border-radius: 4px;
  padding: 0;
  margin: 0;
}

.listItem2 {
  cursor: pointer;
  padding: 6px 12px;
}

.listItem2.active,
.listItem2:hover {
  background-color: var(--off-light-grey);
}
