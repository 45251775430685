.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 3.125rem;
  color: var(--slate);
  padding: .5rem;
  box-sizing: border-box;
}

.inline {
  min-height: 2.75rem;
  width: fit-content;
  border-radius: 6px;
  padding-right: 1rem;
  font-size: .875rem;
}

.center {
  justify-content: center;
}

.icon {
  flex-shrink: 0;
  margin-right: .5rem;
}

.alert a {
  color: var(--green-5);
}

.error {
  background-color: var(--razz-20);
}

.error .icon {
  color: var(--razz);
}

.info {
  background-color: var(--lemon-20);
}

.info .icon {
  color: var(--lemon-darken-20);
}

.inline.withoutIcon {
  padding-left: 1rem;
}
