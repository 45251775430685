.header {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.secondaryHeader {
  font-weight: 600;
  font-size: 16px;
}

.icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
  margin-top: 5px;
}
.icon path {
  fill: var(--slate-darken-20);
}

.headerText {
  flex-grow: 2;
}

.close:hover {
  cursor: pointer;
}

.modalInner {
  display: inline-block;
  padding: 15px;
}

.buttonRow {
  float: right;
  margin-bottom: 4px;
  margin-top: 40px;
}

.description {
  margin-bottom: 24px;
}

.schedulingOption {
  border: 2px solid var(--slate-25);
  box-sizing: border-box;
  border-radius: 6px;
  height: 46px;
  flex-direction: row;
  display: flex;
  padding: 12px 16px;
  margin: 14px 0;
  cursor: pointer;
  line-height: 20px;
  font-size: 14px;
}

.schedulingOption svg {
  height: 18px;
  width: 18px;
  margin-right: 14px;
}
.schedulingOption path {
  fill: var(--slate-darken-20);
}

.schedulingOptionSelected {
  background: var(--apple-20);
  border: 2px solid var(--eden);
  box-sizing: border-box;
  border-radius: 6px;
  height: 46px;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  padding: 12px 16px;
  margin: 14px 0;
  line-height: 20px;
  font-size: 14px;
}

.schedulingOptionSelected svg {
  height: 18px;
  width: 18px;
  margin-right: 14px;
}

.schedulingOptionSelected path {
  fill: var(--slate-darken-20);
}

.buttonRow :first-child {
  margin-right: 12px;
}
