.comment {
  margin: 0.2em 0 0 !important;
  padding: 0.1em 0 0;
}

.comment:hover {
  background-color: var(--grey-0) !important;
}

.comment button.deleteBtn {
  position: absolute;
  top: -16px;
  right: 12px;
  background: white;
  border-radius: 6px;
  height: 32px;
  width: 32px;
  padding: 8px;
  margin: 0;
  box-shadow: 0px 2px 8px rgba(58, 84, 91, 0.15), 0px 0.5px 1px rgba(46, 67, 73, 0.15);
  visibility: hidden;
}

.comment:hover button.deleteBtn {
  visibility: visible;
}

.comment:hover button.deleteBtn:hover {
  background: var(--off-light-grey);
}

.deletedMessage .comment:hover {
  background-color: inherit !important;
}

.comment:hover button.deleteBtn:active {
  background: var(--slate);
}

.comment button.deleteBtn svg,
.comment button.deleteBtn:hover svg {
  stroke: var(--slate-85);
}

.comment button.deleteBtn:active svg {
  stroke: var(--white);
}

.comment .time {
  line-height: 1.4;
  display: block;
  font-weight: normal;
  float: left;
  height: auto;
  margin: 0;
  width: 5em;
}

.comment > .time ~ .header,
.comment > .time ~ .content {
  margin-left: 5em;
}

.comment .header {
  margin: 0 !important;
}

.extraSpace {
  margin: 0.5em 0 0 !important;
}

.commentText {
  margin: 0 !important;
}

.patientTime {
  font-size: 10px;
  padding: 0;
  width: auto;
}

.comment .spacer {
  color: var(--grey-30) !important;
  float: left;
}

.comment > .spacer ~ .header,
.comment > .spacer ~ .content {
  margin-left: 5em;
}
