.submitAVSOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.submitAVSContainer {
  height: auto;
  max-height: fit-content;
  width: 381px;
  background: var(--white);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  outline: none;
  padding: 32px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.submitAVSContainer .topNav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.topNav .header {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: var(--secondary);
  margin-left: 6px;
  margin-right: 24px;
}

.submitAVSContainer .body {
  margin: 26px 6px;
}

.submitAVSContainer .bottomNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bottomNav .cancelButton {
  color: var(--eden);
  border: 1px solid var(--eden);
  margin-right: 12px;
}

.bottomNav .cancelButtonDisabled {
  cursor: default;
  color: var(--slate-35);
  border: 1px solid var(--slate-05);
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.submitAVSContainer .svgCircleSpinner {
  -webkit-animation: svg-animation 1s steps(8, end) infinite;
  -moz-animation: svg-animation 1s steps(8, end) infinite;
  animation: svg-animation 1s steps(8, end) infinite;
}
