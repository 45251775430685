.menuTrigger {
  width: fit-content;
  height: fit-content;
  user-select: none;
  cursor: pointer;
  outline: none;
}

.items {
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 2px 10px 0 rgba(58, 84, 91, 0.1), 0 1px 6px 0 rgba(45, 65, 71, 0.1);
  background: var(--white);
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0;
  list-style: none;
  z-index: 999;
  outline: none;
  width: max-content;
}

.item {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
}

.focusedItem {
  background-color: var(--slate-05);
}
