.underlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(24, 24, 24, 0.69);
}

.overlay {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 2rem;
    border-radius: 10px;
}

.title {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 2rem 0;
    font-size:  1.5rem;
    font-weight: 400;
    text-align: center;
    padding: 0 2rem;
    max-width: 20.5rem;
}

.title .icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem 0 0;
    flex-shrink: 0;
}

.title .closeButton {
    margin-left: auto;
    align-self: center;
}

.closeButton {
    border: none;
    background: transparent;
    width: fit-content;
    cursor: pointer;
    padding: 0;
    align-self: flex-end;
    outline: none;
}

.closeIcon {
    color: var(--slate);
    margin: 0 !important;
}

.buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    flex-grow: 1;
}

.buttons button {
    flex-grow: 1;
}

.description {
    width: 23.125rem;
    margin-bottom: 1rem;
}

.title.withDescription  {
    padding: 0;
    margin: 0 0 1rem 0;
    min-width: 100%;
    text-align: left;
}
