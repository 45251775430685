.dropdown {
  top: -4px;
  padding: 16px 16px 4px;
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  width: 212px;
  cursor: default;

  align-items: center;
}

.dropdownHeader {
  font-size: 14px;
  line-height: 20px;
  color: var(--slate-darken-20);
  padding-bottom: 8px;
}

.options {
  padding-bottom: 24px;
}

.buttonWrapper {
  padding-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  height: 43px;
}

.headerContainer {
  padding-bottom: 32px;
  display: flex;
  flex-direction: row;
  flex-basis: fit-content;
}

.modalHeader {
  font-size: 24px;
  line-height: 135%;
  color: var(--secondary);
  width: 100%;
  flex-shrink: 1;
}

.subheader {
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: var(--slate-darken-20);
  padding-bottom: 5px;
}

.xicon {
  margin-top: 2px;
  cursor: pointer;
}

.body {
  font-size: 14px;
  line-height: 20px;
  color: var(--slate);
  padding-bottom: 16px;
}

.currentStatusContainer {
  font-size: 14px;
  color: var(--slate);
}

.currentStatusContainer span {
  margin-right: 8px;
}

:global(.ui.modal > .content.backToWorkOverride) {
  border-radius: 6px !important;
  padding: 32px 32px 40px 32px;
}

.buttonSpinner {
  margin: 9.5px 0 9.5px 0;
}

.error,
.errorHidden {
  width: 100%;
  border-radius: 6px;
  padding: 10.5px 12.5px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  background: var(--razz-10);
  font-size: 14px;
  line-height: 20px;
  color: var(--razz-darken-20);
}

.errorHidden {
  display: none;
}

.errorIcon {
  margin-top: 2px;
  margin-right: 8.67px;
}

.noStatusSetMessageContainer {
  font-size: 11px;
  line-height: 135%;
  color: var(--slate-85);
  white-space: pre-wrap;
  padding-bottom: 8px;
}

.noStatusSetMessageContainer > div {
  margin-bottom: 8px;
}
