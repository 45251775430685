.card {
  border-radius: 8px;
  background-color: var(--slate-05);
  padding: 24px 0 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: var(--slate-darken-20);
}
