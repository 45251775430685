.buttonWrapper {
  height: 40px;
  min-height: 40px;
  margin-right: 8px;
  padding-right: 8px;
  background-color: var(--white);
  border: 2px solid var(--slate-05);
  border-radius: 4px;
  z-index: 100;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: relative;
}

.buttonWrapper .dropdown svg {
  margin-left: 0;
}

.buttonWrapper svg {
  margin-left: 8px;
}

.svgCirclePlus {
  height: 36px;
  stroke: var(--white);
  fill: var(--apple);
}

.buttonWrapper:hover .svgCirclePlus {
  fill: var(--primary-dark);
}

.svgCircleStop {
  height: 36px;
  stroke: var(--white);
  fill: var(--razz);
}

.disabled .svgCirclePlus,
.disabled:hover .svgCirclePlus {
  stroke: var(--white);
  fill: var(--slate-15);
}

.buttonWrapper:hover .svgCircleStop {
  fill: var(--razz-darken-20);
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.svgCircleSpinner {
  -webkit-animation: svg-animation 1s steps(8, end) infinite;
  -moz-animation: svg-animation 1s steps(8, end) infinite;
  animation: svg-animation 1s steps(8, end) infinite;
}

.labelText {
  margin-left: 8px;
  margin-right: 4px;
  font-weight: 600;
}

.tooltip {
  visibility: hidden;
  background-color: var(--slate-darken-20);
  color: var(--white);
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  display: inline-block;
  z-index: 1;
  top: 38px;
  font-size: 12px;
  position: absolute;
  min-width: 160px;
  right: 0;
}

.hasHover:hover .tooltip {
  visibility: visible;
}

.loading {
  cursor: not-allowed;
}

.loading .buttonInner {
  color: var(--text-dark);
}

.startVisit {
  background-color: var(--eden);
  border-color: var(--eden);
}

.stopVisit {
  background-color: var(--razz);
  border-color: var(--razz);
}

.startVisit:hover,
.startVisitOpen {
  background-color: var(--eden-darken-20);
  border-color: var(--eden-darken-20);
}

.stopVisit:hover {
  background-color: var(--razz-darken-20);
  border-color: var(--razz-darken-20);
}

.disabled {
  border-color: var(--off-light-grey);
  color: var(--slate-35);
  cursor: default;
}

.buttonInner {
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: var(--white);
  border: 0;
}

.dropdown {
  left: 0;
  top: 10px;
  min-width: 112px;
}

.listText {
  display: inline-block;
  margin-left: 8px;
}

.messageIcon {
  margin-left: 1px;
  margin-right: 1px;
}

.listItem > span {
  display: flex;
  align-items: center;
}

@media (max-width: 1540px) {
  .labelText {
    display: none;
  }

  .dropdown {
    left: unset;
    right: -9px;
  }
}
