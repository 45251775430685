.filesWrapper {
  margin: 8px 18px 8px 22px;
}

.filterMenu {
  display: flex;
  justify-content: flex-end;
}

.status {
  padding: 22px 22px 8px;
}
